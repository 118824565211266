import React, { useEffect, useState } from "react";
import ChangePasswordModal from './ChangePasswordModal';
import EditProfileModal from './EditProfileModal';
import ChangePhoneModal from './ChangePhoneModal';
import ChangeEmail from './ChangeEmail';
import { getMyProfile } from '../../services/api/profileApi';
import Loaders from '../Loaders/index'
import verified from "../../Images/verified.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faPen } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

import CompleteProfileUser from "../../Images/CompleteProfileUser.svg"


function Overview() {
  const navigate = useNavigate();

  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');

  useEffect(() => {
    const storedTheme = localStorage.getItem('theme');
    if (storedTheme) {
      setTheme(storedTheme);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('theme', theme);
  }, [theme]);


  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const data = await getMyProfile();
        console.log(data.data.user, "datadatadata");
        setProfile(data.data.user);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };
    fetchProfile();
  }, []);

  if (loading) {
    return <div>
      <Loaders />
    </div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="row px-1 row-top-margin">
      <div className="col-md-6">
        <div className={` inner-shadow p-4 side-nav-info-details  theme-switcher ${theme === 'dark' ? 'inner-shadow-dark' : 'light-theme'}`}>
          <h3 className={`overview-heading theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`} >
            Profile{" "}
            <span className="float-end">
              {/* <EditProfileModal data={profile} /> */}
               <div>
                    <FontAwesomeIcon
                      icon={faPen}
                      onClick={() => navigate(`/complete-profile`)}
                      style={{ cursor: "pointer" }}
                    />
                    </div>
            </span>
          </h3>
          <hr />
          <div className="d-flex dark-mode" theme="light">
           <div className="" style={{width:"80px"}}>
           <img
              src={profile.profileImage ? profile.profileImage : CompleteProfileUser}
              alt="Profile"
              className="side-bar-profile object-fit-cover" 
              style={{width:"80px", height:"80px"}}
            />
           </div>
            <div className="side-bar-name-nav pb-2 mx-3 pt-2  ">
              <h2 className={` theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>
                <span className="d-flex align-items-center">
                  <span>{profile.name}</span>
                  {profile && profile?.videoVerify && profile?.videoVerify === 1
                    ? <img
                      src={verified}
                      alt="Verified"
                      className="mt-1 ms-2"
                    /> : ''}
                </span> </h2>
              <p className={` theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>{profile?.email}</p>
              {/* <span>Licence : #{profile.licenceNumber}</span> */}
            </div>
          </div>
          <hr />



          <div className="row" >
            <div className="col-sm-6 side-nav-info">
              <p className={` pt-2 pb-2 theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>Gender <span className={` side-nav-info-dot theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>:</span>


              </p>
              <p className={`  theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>Ethnicity <span className={` side-nav-info-dot theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>:</span></p>
            </div>
            <div className="col-sm-6 side-nav-info-details side-nav-info-responsive">
              <p className={` pt-2 pb-2 theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>{profile?.gender === 0 ? "Male" : profile?.gender === 1 ? "Female" : "Other"}</p>

              <p className={`  theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>{profile?.ethnicity || '--'} </p>
            </div>
          </div>
          <div className="row ">
            <div className="col-sm-6 side-nav-info">
              <p className={` pt-2 pb-2 theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>Zodiac <span className={` side-nav-info-dot theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>:</span></p>
              <p className={`  theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>Age <span className={` side-nav-info-dot theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>:</span></p>
            </div>
            <div className="col-sm-6 side-nav-info-details side-nav-info-responsive">
              <p className={`pt-2 pb-2 theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>{profile?.zodiac || "--"}</p>
              <p className={`theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>{profile?.age || "--"} </p>
            </div>
          </div>
          <div className="row dark-mode" theme="light">
            <div className="col-sm-6 side-nav-info">
              <p className={` pt-2 theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>Smoke <span className={` side-nav-info-dot theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>:</span></p>
              {/* <p>About</p> */}
            </div>
            <div className="col-sm-6 side-nav-info-details side-nav-info-responsive">
              <p className={` pt-2 theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>{profile?.smoke || "--"}</p>
              {/* <p>{profile.about} </p> */}
            </div>
          </div>
          <div className="row ">
            <div className="col-sm-6 side-nav-info">
              <p className={` pt-2 pb-2 theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>Drink <span className={` side-nav-info-dot theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>:</span></p>
              <p className={`  theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>Workout <span className={` side-nav-info-dot theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>:</span></p>
            </div>
            <div className="col-sm-6 side-nav-info-details side-nav-info-responsive">
              <p className={` pt-2 pb-2 theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>{profile?.drink || "--"}</p>
              <p className={`  theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>{profile?.workout || '--'} </p>
            </div>
          </div>
          <div className="row ">
            <div className="col-sm-6 side-nav-info">
              <p className={` pt-2 pb-2 theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>Body Type <span className={` side-nav-info-dot theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>:</span></p>
              <p className={`  theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>Hourly Price ($) <span className={` side-nav-info-dot theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>:</span></p>
            </div>
            <div className="col-sm-6 side-nav-info-details side-nav-info-responsive">
              <p className={` pt-2 pb-2 theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>{profile?.bodytype || "--"} </p>
              <p className={`  theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>{profile?.hoursPrice || "--"} </p>
            </div>
          </div>
          {/* <div className="row ">
            <div className="col-sm-6 side-nav-info">
              <p className={` pt-3 theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>Video Verification
                <span className={` side-nav-info-dot theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>:</span></p>
            </div>
            <div className="col-sm-6 side-nav-info-details side-nav-info-responsive">
              <p className={` pt-2 theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>
                <span className="side-nav-info-span">{profile.videoVerify === 0 ? "Pending" : profile.videoVerify === 1 ? "verify" : ""} </span>
              </p>
            </div>
          </div> */}
          <div className="row ">
            <div className="col-sm-6 side-nav-info">
              <p className={` pt-2 theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>About
                <span className={` side-nav-info-dot theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}></span></p>
            </div>
            <div className="col-sm-6 side-nav-info-details side-nav-info-responsive">

              {/* <p className={`pt-2 theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>
    {profile.about.length > 300 ? `${profile.about.substring(0, 100)}...` : profile.about}
  </p> */}
            </div>
          </div>
          <p className={`pt-2 theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`} onClick={toggleExpand} style={{ cursor: "pointer" }}>
            {expanded ? profile?.about : (profile?.about?.length > 300 ? `${profile?.about?.substring(0, 100)}....` : profile?.about)}
          </p>
        </div>
      </div>

      <div className="col-md-6">
        <div className={` inner-shadow p-4  side-nav-info-details  theme-switcher ${theme === 'dark' ? 'inner-shadow-dark' : 'light-theme'}`}>
          {/* <h3 className={` overview-heading theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>
            Signin Methods
          </h3>
          <hr /> */}

          {/* <div className="side-bar-name-nav pb-2 mx-3 pt-2">
            <h5 className={` side-bar-name-h5  theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>Email Address / Phone</h5>
            <p className={`  theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>{profile.email}</p>
          </div>
          <hr /> */}
          <div className="row ">
            <div className="col-sm-6 side-nav-info">
              <h6 className={` pt-2 pb-2 side-bar-name-h6  theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>Email</h6>
              <p className={`  pt-2 pb-2 side-nav-info-details  theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>{profile.email}</p>
            </div>
            <div className="col-sm-6 side-nav-info">
              <ChangeEmail />
            </div>
          </div>
          <hr />
          <div className="row ">
            <div className="col-sm-6 side-nav-info">
              <h6 className={` pt-2 pb-2 side-bar-name-h6  theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>Password</h6>
              <p className={`  side-nav-info-details   theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>******************</p>
            </div>
            <div className="col-sm-6 side-nav-info">
              <ChangePasswordModal />
            </div>
          </div>
          <hr />
          <div className="row ">
            <div className="col-sm-6 side-nav-info">
              <h6 className={` pt-2 pb-2 side-bar-name-h6  theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>Contact phone</h6>
              <p className={`  pt-2 pb-2 side-nav-info-details  theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>
                {`${profile.countryCode}-${profile.mobile}`}</p>
            </div>
            <div className="col-sm-6 side-nav-info">
              <ChangePhoneModal code={profile?.countryCode && profile?.countryCode.split("+")[1]} mobile={profile?.mobile} />
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}

export default Overview;
