import React, { useState, useEffect } from "react";
import { Formik, Field, ErrorMessage, Form as FormikForm } from "formik";
import * as Yup from "yup";
import logo from "../../Images/33512733_8058698_1__1_-removebg-preview.png";
import Form from "react-bootstrap/Form";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import { Link, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhotoFilm } from "@fortawesome/free-solid-svg-icons";
import InputGroup from "react-bootstrap/InputGroup";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Input from "@mui/material/Input";
import Modal from "@mui/material/Modal";
import xmark from "../../Images/x-mark.png";
import LightBulb from "../../Images/3d-render-hand-with-light-bulb-creative-idea.png";
import { getMyProfile, photoUpload, profileComplete, scanDocument } from "../../services";
import { notifyError, notifySuccess } from "../../toastNotifications";
import NavbarSignFlow from "../Login/NavbarSignFlow";
// import CustomDatePickerWithoutMonth from './CustomDatePickerWithoutMonth';
import Image from "../../Images/Group 1948757617.png";
import AttechFile from "./AttechFile";
import { InputAdornment, TextField } from "@mui/material";

import { IconButton } from "@mui/material";
import { Upload } from "@mui/icons-material";
import UploadFile from '../../Images/Vector (1).svg';
import UploadFile2 from '../../Images/Vector (2).svg';
import UploadFile3 from '../../Images/Vector (3).svg';
import Loaders from '../Loaders/index'


function Index() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [profileImageLoading, setProfileImageLoading] = useState(false);

  const [formikData, setFormikData] = useState(null);


  const [imagePreview, setImagePreview] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [imageDataUrl, setImageDataUrl] = useState(null);
  const [modelOpenStatus, setModelOpenStatus] = useState(false);
  const [imageError, setImageError] = useState(null);



  // document
  const [documentPreview, setDocumentPreview] = useState(null);
  const [documentUrl, setDocumentUrl] = useState(null);
  const [documentFile, setDocumentFile] = useState(null);
  const [documentDataUrl, setDocumentDataUrl] = useState(null);
  const [documentError, setDocumentError] = useState(null);


  const [scanOutputData, setScanOutputData] = useState(null);
  const [scanOutputModel, setScanOutputModel] = useState(false);


  function getZodiacSign(dob) {
    const date = new Date(dob); // Convert the input to a Date object
    const month = date.getMonth() + 1; // getMonth() returns 0-based month, so add 1
    const day = date.getDate(); // getDate() returns the day of the month

    switch (true) {
      case (month === 1 && day >= 20) || (month === 2 && day <= 18):
        return "Aquarius";
      case (month === 2 && day >= 19) || (month === 3 && day <= 20):
        return "Pisces";
      case (month === 3 && day >= 21) || (month === 4 && day <= 19):
        return "Aries";
      case (month === 4 && day >= 20) || (month === 5 && day <= 20):
        return "Taurus";
      case (month === 5 && day >= 21) || (month === 6 && day <= 20):
        return "Gemini";
      case (month === 6 && day >= 21) || (month === 7 && day <= 22):
        return "Cancer";
      case (month === 7 && day >= 23) || (month === 8 && day <= 22):
        return "Leo";
      case (month === 8 && day >= 23) || (month === 9 && day <= 22):
        return "Virgo";
      case (month === 9 && day >= 23) || (month === 10 && day <= 22):
        return "Libra";
      case (month === 10 && day >= 23) || (month === 11 && day <= 21):
        return "Scorpio";
      case (month === 11 && day >= 22) || (month === 12 && day <= 21):
        return "Sagittarius";
      case (month === 12 && day >= 22) || (month === 1 && day <= 19):
        return "Capricorn";
      default:
        return "Unknown";
    }
  }


  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .test(
        "notEmptyAfterTrim",
        "Name is required",
        (value) => value.trim() !== ""
      )
      .required("Name is required"),
    identity: Yup.string()
      .oneOf(["0", "1", "2"], "identity is required")
      .required("identity is required"),
    hoursPrice: Yup.number()
      .positive("positive number allowed only")
      .required("Hourly price is required")
      .max(
        10000000,
        "You can set the hourly price up to 10,000,000 dollars only."
      ),

  });

  const handleSubmit = async (values, { setSubmitting }) => {
    console.log(`values`, values);
    console.log(`documentFile`, documentFile);


    if (!imageFile && !formikData?.profileImage) {
      notifyError(`Profile image required`);
      return;
    } else {
    }

    if (!documentFile && !formikData?.document) {
      notifyError(`Identity document image required`);
      return;
    } else {
    }

    const {
      name,
      identity,
      hoursPrice,
    } = values;
    setSubmitting(true);

    if (!imageFile && !formikData?.profileImage && !documentFile && !formikData?.document) {
      setImageError(true);
      setSubmitting(false);
    } else {
      setImageError(false);
      try {
        const newForm = new FormData();
        const file = imageFile;
        if (!file && !formikData?.profileImage) {
          console.error("No file selected");
          notifyError(`Image required`);
          setSubmitting(false);
          return;
        }

        if (file) {
          const reader = new FileReader();
          const dataURL = imageDataUrl;
          const byteString = atob(dataURL.split(",")[1]);
          const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
          const ab = new ArrayBuffer(byteString.length);
          const ia = new Uint8Array(ab);
          for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
          }
          const blob = new Blob([ab], { type: mimeString });
          reader.readAsDataURL(file);
          newForm.append("profileImage", blob, file.name);
        } else {
          newForm.append("profileImage", formikData?.profileImage);
        }


        const documentFileInner = documentFile;

        if (!documentFileInner && !formikData?.document
        ) {
          console.error("No file selected");
          notifyError(`Identity document image required`);
          setSubmitting(false);
          return;
        }

        if (documentFileInner) {
          const reader = new FileReader();
          const documentDataURL = documentDataUrl;
          const documentByteString = atob(documentDataURL.split(",")[1]);
          const documentMimeString = documentDataURL.split(",")[0].split(":")[1].split(";")[0];
          const docab = new ArrayBuffer(documentByteString.length);
          const docia = new Uint8Array(docab);
          for (let i = 0; i < documentByteString.length; i++) {
            docia[i] = documentByteString.charCodeAt(i);
          }
          const docblob = new Blob([docab], { type: documentMimeString });
          reader.readAsDataURL(documentFileInner);

          newForm.append("document", docblob, documentFileInner.name);
        } else {
          newForm.append("document", formikData?.document);
        }
        newForm.append("name", name);
        newForm.append("identity", parseInt(identity));
        newForm.append("zodiac", getZodiacSign(formikData?.dob || new Date()));
        newForm.append("hoursPrice", hoursPrice);

        if (scanOutputData) {
          newForm.append("gender", scanOutputData?.gender?.toString() || "0");
        }
        // console.log("formdata is hereeee ---->>>>", formikData);

        // formData.append("profileImage", blob, file.name);
        try {
          const response = await profileComplete(newForm);
          console.log("profileComplete response ==>>", response);
          if (response.statusCode === 200) {
            notifySuccess(response.message);
            // handleClose();
            // setTimeout(() => {
            window.location.reload();
            navigate(-1);
            // }, 2000)
          }
        } catch (error) {
          console.error("Error uploading photo:", error);
          notifyError(error?.response?.data?.message || `Something went wrong`);
          // handleClose();
        } finally {
          setSubmitting(false);
        }
        // };

        // Read the file as a binary string
        // Use readAsDataURL to set image preview correctly
      } catch (error) {
        console.error("Error uploading file:", error);
      } finally {
        setSubmitting(false);
      }
    }
  };



  const handleFileChange = async (event) => {
    // console.log("working================================here");
    setProfileImageLoading(true);
    try {
      const file = event.target.files[0];
      setImageFile(file);
      setImageError(false);
      console.log(file, "filefilefilefile");
      if (!file) {
        console.error("No file selected");
        return;
      }
      const reader = new FileReader();
      reader.onloadend = async () => {
        const dataURL = reader.result;
        console.log(dataURL, "dataURL");
        setImageDataUrl(dataURL);
        // Convert data URL to Blob
        const byteString = atob(dataURL.split(",")[1]);
        const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([ab], { type: mimeString });
        // Create form data object to send binary data
        const formData = new FormData();
        formData.append("file", blob, file.name);
        try {
          // Upload the file using the photoUpload function
          const imageUpload = await photoUpload(formData);
          if (imageUpload.statusCode === 200) {
            console.log(imageUpload.data.imageUrl, "imageUpload.data.imageUrl");
            setImageUrl(imageUpload.data.imageUrl);
            setImagePreview(imageUpload.data.imageUrl);
            console.log(imageUrl, "imageUrl");
          }
        } catch (error) {
          console.error("Error uploading image:", error);
        } finally {
          setProfileImageLoading(false);
        }
      };
      // Read the file as a binary string
      reader.readAsDataURL(file); // Use readAsDataURL to set image preview correctly
    } catch (error) {
      setProfileImageLoading(false);
      console.error("Error uploading file:", error);
    } finally {
      // setProfileImageLoading(false);
    }
  };



  //  document change handler


  const closeScanOutputModel = () => {
    setScanOutputModel(false);
  }


  const handleFileUpload = async (event) => {
    setLoading(true)
    try {
      const file = event.target.files[0];
      setDocumentFile(file);
      setDocumentError(false);
      if (!file) {
        console.error("No file selected");
        return;
      }
      const reader = new FileReader();
      reader.onloadend = async () => {
        const dataURL = reader.result;
        console.log(dataURL, "dataURL");
        setDocumentDataUrl(dataURL);
        // Convert data URL to Blob
        const byteString = atob(dataURL.split(",")[1]);
        const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([ab], { type: mimeString });
        // Create form data object to send binary data
        const formData = new FormData();
        formData.append("document", blob, file.name);
        formData.append("type", 0);
        try {
          // Upload the file using the photoUpload function
          const response = await scanDocument(formData);
          console.log(response, "imageUpload in scanDocument");
          if (response.statusCode === 200) {
            setScanOutputModel(true);
            setScanOutputData(response?.data)

          }
        } catch (error) {
          console.error("Error uploading image:", error);
        } finally {
          setLoading(false)
        }
      };
      // Read the file as a binary string
      reader.readAsDataURL(file); // Use readAsDataURL to set image preview correctly
    } catch (error) {
      setLoading(false)

      console.error("Error uploading file:", error);
    } finally {
      // setLoading(false)
    }
  };



  const [theme, setTheme] = useState(localStorage.getItem("theme") || "light");

  useEffect(() => {
    const storedTheme = localStorage.getItem("theme");
    if (storedTheme) {
      setTheme(storedTheme);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("theme", theme);
  }, [theme]);



  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const data = await getMyProfile();
        console.log(data.data.user, "datadatadata");
        setFormikData(data.data.user);
      } catch (err) {
        // setError(err.message);
        // setLoading(false);
      }
    };
    fetchProfile();
  }, []);

  // if (loading) {
  //   return <div><Loaders /></div>
  // }




  return (
    <div className={`container-fluid theme-switcher ${theme === "dark" ? "black-color" : "light-theme"
      }`}>
      <div
        className={`container theme-switcher ${theme === "dark" ? "black-color" : "light-theme"
          }`}
      >
        <div className="row">
          <div className="col-md-6 d-flex justify-content-center align-items-center px-5">
            <div className="text-center">
              <img src={Image} alt="" />
              <p className="the-details pt-4">
                Enter the details to setup the responders profile
              </p>
            </div>
          </div>

          <div
            className={`col-md-6 login-right-section m-auto theme-switcher ask-to-sign-scroll-hide ${theme === "dark" ? "dark-theme-only-white" : "light-theme"
              }`}
          >
            <div className="ask-to-sign  px-3">
              <h2 className="d-flex mt-4 pt-5">
                Setup Responders Profile <img src={logo} alt="" />
              </h2>

              {formikData && (
                <Formik
                  initialValues={{
                    name: formikData?.name || "",
                    identity: formikData?.identity ? formikData?.identity.toString() : "0",
                    hoursPrice: formikData?.hoursPrice || "",
                  }}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({
                    formikProps,
                    isSubmitting,
                    values,
                    setFieldValue,
                    errors,
                  }) => (
                    <FormikForm className="mt-4 d-grid gap-2">
                      <center className="pt-4">
                        <input
                          type="file"
                          id="profileImage"
                          name="profileImage"
                          onChange={handleFileChange}
                          style={{ display: "none" }}
                        />
                        <label htmlFor="profileImage">
                          {imagePreview || formikData?.profileImage ? (
                            <img
                              style={{ marginTop: "-25px" }}
                              src={imagePreview || formikData?.profileImage}
                              alt="Preview"
                              className="side-bar-profile object-fit-cover"
                            />
                          ) : (
                            <Button
                              component="span"
                              role={undefined}
                              variant="contained"
                              tabIndex={-1}
                              className="upload-file"
                              style={{ marginTop: "-25px" }}
                            >
                              <FontAwesomeIcon
                                icon={faPhotoFilm}
                                className="upload-file-icon mt-4 pt-1"
                              />
                            </Button>
                          )}
                        </label>
                        {/* {imageError ? (
                        <span className="text-danger">Profile image required</span>
                      ) : (
                        ""
                      )} */}
                        <br />
                        <Form.Label
                          htmlFor="profileImage"
                          className={`form-label mt-2  theme-switcher ${theme === "dark" ? "dark-theme-only-white" : "light-theme"
                            }`}
                        >
                          Profile Picture
                        </Form.Label>
                      </center>

                      {/* Conditionally render imagePreview */}
                      <Form.Group>
                        <Form.Label
                          className={`form-label  theme-switcher ${theme === "dark" ? "dark-theme-only-white" : "light-theme"
                            }`}
                        >
                          Full Name
                        </Form.Label>
                        <Field
                          type="text"
                          name="name"
                          placeholder="Enter Full Name"
                          className={`form-control form-input  theme-switcher ${theme === "dark" ? "dark-theme-only-color" : "light-theme"
                            }`}
                        />
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="text-danger"
                        />
                      </Form.Group>

                      <Form.Group>
                        <Form.Label
                          className={` ' form-label mt-2  theme-switcher ${theme === "dark" ? "dark-theme-only-white" : "light-theme"
                            }`}
                        >
                          Authenticate Your Identity
                        </Form.Label>
                        <Field
                          as="select"
                          name="identity"
                          className={`form-control form-input custom-select  theme-switcher ${theme === "dark" ? "dark-theme-only-color" : "light-theme"
                            }`}
                        >
                          <option value="0">Driving License</option>
                          <option value="1">Passport</option>
                          <option value="2">Country ID</option>
                        </Field>
                        <Box
                          sx={{
                            p: 2,
                            borderRadius: 7,
                            backgroundColor: theme === "dark" ? "#333" : "#f9f9f9",
                            color: theme === "dark" ? "#fff" : "#000",
                            textAlign: "center",
                            width: "100%",
                            margin: "0 auto",
                          }}
                          className="mt-4"
                        >


                          <Box
                            sx={{
                              position: "relative",
                              borderRadius: 7,
                              p: 4,
                              backgroundColor: "#e0e0e0",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flexDirection: "column",
                              height: 200,
                              overflow: "hidden",
                            }}
                          >
                            {documentDataUrl || formikData?.document ? (
                              <img
                                src={documentDataUrl || formikData?.document}
                                alt="Uploaded Preview"
                                style={{
                                  maxWidth: "100%",
                                  //   maxHeight: "100%",
                                  borderRadius: 8,
                                  objectFit: "cover",
                                }}
                              />
                            ) : (
                              <label htmlFor="upload-file" style={{ cursor: "pointer" }}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                  }}
                                >
                                  {/* <Upload sx={{ fontSize: 50, color: "#aaa" }} />
              <p style={{ margin: 0, fontSize: 14, color: "#666" }}>
                Upload File
              </p> */}
                                  <img src={UploadFile2} alt="" className="m-auto" />
                                </Box>
                              </label>
                            )}
                            <input
                              type="file"
                              id="upload-file"
                              onChange={handleFileUpload}
                              style={{ display: "none" }}
                            />
                            <IconButton
                              sx={{
                                position: "absolute",
                                bottom: 15,
                                right: 15,
                                width: 50,
                                height: 50,

                                background: "linear-gradient(45deg, #F10B80, #830D9A)",
                                color: "#fff",
                                boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
                              }}
                              component="label"
                            >
                              <input
                                type="file"
                                hidden
                                accept="image/*"
                                onChange={handleFileUpload}
                              />
                              <img
                                src={imagePreview ? UploadFile3 : UploadFile}
                                alt=""
                                className="m-auto"
                                style={{ width: "18px" }}
                              />
                            </IconButton>
                          </Box>
                        </Box>
                      </Form.Group>

                      <Form.Group>
                        <Form.Label
                          className={`form-label mt-3  theme-switcher ${theme === "dark" ? "dark-theme-only-white" : "light-theme"
                            }`}
                        >
                          Hourly Price ($)
                        </Form.Label>

                        <TextField
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          name="hoursPrice"
                          defaultValue={formikData?.hoursPrice || 0}
                          onChange={(e) => setFieldValue('hoursPrice', e.target.value)}
                          // placeholder="Enter Hourly Price"

                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start" sx={{ color: "#888" }}>
                                $
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <InputAdornment position="end" sx={{ color: "#888" }}>
                                /hr
                              </InputAdornment>
                            ),
                          }}
                          fullWidth
                          sx={{
                            borderRadius: 20,
                            "& .MuiOutlinedInput-root": {
                              borderRadius: 20,
                              padding: "0 8px", // Adjust padding for height
                              "& fieldset": {
                                borderColor: "#ccc",
                              },
                              "&:hover fieldset": {
                                borderColor: "#aaa",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "#f10b80",
                                boxShadow: "0 0 0 0.25rem #f10b8048",
                              },
                            },
                            "& input": {
                              textAlign: "center",
                              padding: "14px 0px", // Adjust padding for the input
                            },
                          }}

                        />
                        <ErrorMessage
                          name="hoursPrice"
                          component="div"
                          className="text-danger"
                        />
                      </Form.Group>

                      <div className="d-grid gap-2 ">


                        {loading || profileImageLoading ?
                          <button className="sign-in-btn mt-4 mb-5 py-3" disabled={true}>
                            {loading ? `uploading identity document...` : profileImageLoading ? `uploading profile image...` : ``}
                          </button> :
                          isSubmitting ?
                            <button className="sign-in-btn mt-4 mb-5 py-3" disabled={true}>
                              {`updating...`}
                            </button>
                            :
                            <button className="sign-in-btn mt-4 mb-5 py-3">
                              Complete profile
                            </button>}
                      </div>
                    </FormikForm>
                  )}
                </Formik>)}
            </div>
          </div>
        </div>
      </div>
      {scanOutputModel &&
        (<div class="css-overlay">
          <div class="css-modal">
            <div class="css-header">
              <span class="css-check-icon">✓</span>
              <span class="css-title">ID Authenticated</span>
            </div>

            <p class="css-subtitle">Ask Human will autofill your mentioned data.</p>

            <div class="css-input-fields">
              <input type="text" value={`${scanOutputData
                ? scanOutputData?.gender == 0
                  ? `Male`
                  : scanOutputData?.gender == 1
                    ? `Female`
                    : `TS`
                : `TS`
                }`} readonly />
              <input type="text" value="Asian" readonly />
              <input type="text" value={getZodiacSign(formikData?.dob || new Date())} readonly />
              <input type="text" value={`${formikData?.age || 18}years`} readonly />
            </div>

            <button class="css-proceed-btn" onClick={() => closeScanOutputModel()}>Proceed</button>
          </div>
        </div>)}


    </div >
  );
}

export default Index;
