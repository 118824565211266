import React from 'react'
import trustedus from '../../Images/trusted-us.png';
import trustedus1 from '../../Images/trusted-us-1.png';
import { useState, useEffect } from 'react';

const TrustedUs = () => {
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');

  useEffect(() => {
    const storedTheme = localStorage.getItem('theme');
    if (storedTheme) {
      setTheme(storedTheme);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('theme', theme);
  }, [theme]);

  return (

    <div id='about'>
      <div className={` container-fluid trusted-us  theme-switcher ${theme === 'dark' ? 'black-color' : 'light-theme'}`}>
        <div className="container pt-5 pb-5">
          <h5 className={`why-choose-h5  pb-2 theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}>Our Simple Process</h5>
          <div className="row">
            <div className="col-md-6">
              <img src={trustedus} alt="" className='trusted-us-image' />
            </div>
            {/* <div className="col-md-6">
              <h2 className={`'why-choose-h5 text-start pt-5 theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}>Lorem Ipsum text Heading</h2>
              <p className={` ' theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}>Lorem Ipsum is simply dummy text of the printing and  typesetting industry. Lorem Ipsum has been the industry's standard dummy  text ever since the 1500s, when an unknown printer took a galley of  type and scrambled it to make a type specimen book. It has survived not  only five centuries, but also the leap into electronic typesetting,  remaining essentially unchanged.</p>
            </div> */}
            <div className="col-md-6">
              <ul style={{ listStyleType: "disc" }}>
                <li>
                  <div>
                    <p style={{ fontWeight: "bold" }}>Search </p>
                    <p>Use hashtags to search for people who match your goals and interests. Whether it’s #Millionaire, #FitnessMuscular, or #Chef, we’ve made finding the right person effortless.</p>
                  </div>
                </li>
                <li>
                  <div>
                    <p style={{ fontWeight: "bold" }}>Ask </p>
                    <p>Post your question, and our platform connects you with the most relevant individuals who can provide authentic answers. No bots, no AI—just real people.</p>
                  </div>
                </li>
                <li>
                  <div>
                    <p style={{ fontWeight: "bold" }}>Learn to</p>
                    <p>Get valuable, tailored advice based on real-world experience. Whether it’s tips, insights, or actionable steps, learn directly from those who’ve achieved success.</p>
                  </div>
                </li>
                <li>
                  <div>
                    <p style={{ fontWeight: "bold" }}>Connect</p>
                    <p>Turn conversations into relationships. Build connections with mentors, peers, and role models who inspire and guide you on your journey.</p>
                  </div>
                </li>
                <li>
                  <div>
                    <p style={{ fontWeight: "bold" }}>Payment Process</p>
                    <p>If an expert accepts your invite, pay for the chat on an hourly basis. Likewise, if you accept an invite, get paid for your answer.</p>
                  </div>
                </li>
              </ul>
            </div>

            {/* <div className="col-md-6">
          <h2 className={` 'why-choose-h5 text-start pt-5 theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme' }`}>Lorem Ipsum text Heading</h2>
          <p  className={` ' theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme' }`}>Lorem Ipsum is simply dummy text of the printing and  typesetting industry. Lorem Ipsum has been the industry's standard dummy  text ever since the 1500s, when an unknown printer took a galley of  type and scrambled it to make a type specimen book. It has survived not  only five centuries, but also the leap into electronic typesetting,  remaining essentially unchanged.</p>
        </div> */}
            <div className="col-md-6">
              <ul style={{ listStyleType: "disc" }}>
                <li>
                  <div>
                    <p style={{ fontWeight: "bold" }}>Engage in the Conversation</p>
                    <p>The expert will provide detailed answers, or you will respond to the query with your knowledge.</p>
                  </div>
                </li>
                <li>
                  <div>
                    <p style={{ fontWeight: "bold" }}>Refunds for Unaccepted Invites</p>
                    <p>If no one accepts your invite, your $1 will be refunded.</p>
                  </div>
                </li>
                <li>
                  <div>
                    <p style={{ fontWeight: "bold" }}>Dispute and Refund Policy</p>
                    <p>If you are not satisfied with the answer, you can dispute it and request a refund.</p>
                  </div>
                </li>
                <li>
                  <div>
                    <p style={{ fontWeight: "bold" }}>Referral Option</p>
                    <p>If you are invited but unavailable, refer the invitation to someone else who can provide the answer and accept the invite.</p>
                  </div>
                </li>
                <li>
                  <div>
                    <p style={{ fontWeight: "bold" }}>Feedback and Reviews</p>
                    <p>Share your feedback on the quality of answers or the questions asked to help maintain a high standard within our community.</p>
                  </div>
                </li>
              </ul>
            </div>

            <div className="col-md-6">
              <img src={trustedus1} alt="" className='trusted-us-image' />
            </div>



          </div>
        </div>
      </div>
    </div>
  )
}

export default TrustedUs