import React, { useEffect, useState } from 'react';
import logo from '../../Images/logo-mini.png';
import Form from 'react-bootstrap/Form';
import { Button, styled } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhotoFilm } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import { addNote, editNote, getDraftNoteDetails } from '../../services/api/notesApi';
import { notifyError, notifySuccess } from '../../toastNotifications';
import { photoUpload, searchHastags } from '../../services';
// import Modal from 'react-bootstrap/Modal';
import xmark from "../../Images/x-mark.png";
import { getWalletDetails } from '../../services/api/walletApi';

import { faX } from '@fortawesome/free-solid-svg-icons';

import DeleteIcon2 from "../../Images/cross.svg";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Input from "@mui/material/Input";
import Typography from "@mui/material/Typography";

import CompletionPercentage from './CompletionPercentage';
import FileUpload from '../../Images/Add photos.svg';
import WhiteVerify from '../../Images/white-verify-2.png';

import { X } from 'lucide-react'

import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: '50rem',
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};


function Index() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [note, setNote] = useState('');
  const [selectedMediaArr, setSelectedMediaArr] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0); // Progress bar state
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');
  const [selectedMedia, setSelectedMedia] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showAmountCheckModal, setShowAmountCheckModal] = useState(false);
  const [userProfileCompletionPercentage, setUserProfileCompletionPercentage] = useState(localStorage.getItem('completionPercentage') || 0);





  const [dataToSend, setDataToSend] = useState(null);

  const [isOpen, setIsOpen] = useState(false)
  const [hashtagsArray, setHashtagsArray] = useState([])
  const [inputValue, setInputValue] = useState('')
  const [searchedHastagsData, setSearchedHastagsData] = useState([]);


  const handleOpen = () => setShowAmountCheckModal(true);
  const handleClose = () => setShowAmountCheckModal(false);

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const handleImageClick = (imageUrl) => {
    setSelectedMedia(imageUrl);
    setShowModal(true);
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];

    if (!file) {
      console.error("No file selected");
      return;
    }

    const formData = new FormData();
    formData.append("file", file, file.name);

    try {
      const mediaUpload = await photoUpload(formData, (event) => {
        setUploadProgress(Math.round((100 * event.loaded) / event.total));
      });

      if (mediaUpload.statusCode === 200) {
        setSelectedMediaArr([
          ...selectedMediaArr,
          {
            key: selectedMediaArr.length,
            value: mediaUpload.data.imageUrl,
            type: file.type.split('/')[0]
          }
        ]);
      }
    } catch (error) {
      console.error("Error uploading media:", error);
    }
  };

  function extractHashtags(input) {
    return [...new Set(
      input.match(/#[a-zA-Z0-9]+/g)?.map(tag => tag.slice(1).toLowerCase()) || []
    )];
  }

  const handleAddOrEditNoteBeforeHashtags = async () => {

    if ((note && note.trim() !== "") || (selectedMediaArr && selectedMediaArr.length > 0)) {
      setIsOpen(true);
    } else {
      notifyError("Please type your question or upload any related media to proceed further");

    }

  }

  const handleAddOrEditNote = async (status) => {
    // console.log(`note`, note)

    // console.log(`resultNote`, resultNote); 


    if ((note && note.trim() !== "") || (selectedMediaArr && selectedMediaArr.length > 0)) {
      // const hashtagsArray = extractHashtags(note);

      let data = id
        ? {
          id,
          note: note.trim(),
          media: selectedMediaArr && selectedMediaArr.length > 0 ? selectedMediaArr.map((val) => val.value) : [],
          hashtags: hashtagsArray && hashtagsArray.length > 0 ? hashtagsArray : [],
          status
        }
        : {
          note: note.trim(),
          media: selectedMediaArr && selectedMediaArr.length > 0 ? selectedMediaArr.map((val) => val.value) : [],
          hashtags: hashtagsArray && hashtagsArray.length > 0 ? hashtagsArray : [],
          status
        };
      if (status == "0") {
        try {
          const response = id ? await editNote(data) : await addNote(data);
          if (response.statusCode === 200) {
            // localStorage.setItem("saved-note-hashtags", JSON.stringify(hashtagsArray && hashtagsArray.length > 0 ? hashtagsArray : []));

            notifySuccess(`Note added to draft successfully`);
            navigate('/profile');
          }
        } catch (error) {
          console.error("Error in addNote:", error);
        }
      } else if (status == "1") {
        // try {
        // const response = await getWalletDetails();
        // console.log("getWalletDetails response is here -->", response);
        // if (response?.statusCode === 200) {
        //   if (!response?.data?.checkWallet?.ammount || (response?.data?.checkWallet?.ammount && response?.data?.checkWallet?.ammount < 1)) {
        //     handleOpen();
        //   } else {



        //  commented out for open here hashtags modal
        try {
          const response = id ? await editNote(data) : await addNote(data);
          if (response.statusCode === 200) {
            navigate(`/user-list/${response?.data?.createNotes._id || id}`);
            localStorage.setItem("saved-note", JSON.stringify(data));
            localStorage.setItem("saved-note-hashtags", JSON.stringify(hashtagsArray && hashtagsArray.length > 0 ? hashtagsArray : []));
          }
        } catch (error) {
          console.error("Error in addNote:", error);
        }

        // console.log("addNote modified here =================================================================")
        // setIsOpen(true)
        // setDataToSend(data)
      }
      // }
      // } catch (error) {
      //   console.error("Error in getWalletDetails :", error);
      //   notifyError(error?.response?.data?.message || "Something went wrong");
      // }
      // }
    } else {
      notifyError("Please type your question or upload any related media to proceed further");
    }
  };

  const checkUrlType = async (url) => {
    const extension = url.split('.').pop().toLowerCase();

    if (extension === 'jpg' || extension === 'jpeg' || extension === 'png' || extension === 'gif' || extension === 'bmp') {
      return 'image';
    } else if (extension === 'mp4' || extension === 'avi' || extension === 'mov' || extension === 'mkv' || extension === 'webm') {
      return 'video';
    } else {
      return 'unknown';
    }
  };

  const handleGetNoteDetails = async () => {
    try {
      const response = await getDraftNoteDetails(id);

      if (response.statusCode === 200) {
        setNote(response?.data?.notesDraftDetails?.note || "");
        let media = response?.data?.notesDraftDetails?.media || [];
        let arr = [];

        for (let i = 0; i < media.length; i++) {
          const element = media[i];
          arr.push({
            key: arr.length,
            value: element,
            type: await checkUrlType(element)
          });
        }

        setSelectedMediaArr(arr);
      }
    } catch (error) {
      console.error("Error in getDraftNoteDetails :", error);
      notifyError(error?.response?.data?.message || `Something went wrong`);
    }
  };

  const handleSaveLocalNote = async (media) => {
    let mediaArr = [];

    for (let i = 0; i < media.length; i++) {
      const element = media[i];
      mediaArr.push({
        key: mediaArr.length,
        value: element,
        type: await checkUrlType(element)
      });
    }

    setSelectedMediaArr(mediaArr);
  };

  useEffect(() => {
    if (id) {
      handleGetNoteDetails();
    } else {
      if (localStorage.getItem("saved-note")) {
        setNote(JSON.parse(localStorage.getItem("saved-note")).note);
        let localMedia = JSON.parse(localStorage.getItem("saved-note")).media || [];
        handleSaveLocalNote(localMedia);
      }
    }
  }, []);

  useEffect(() => {
    const storedTheme = localStorage.getItem('theme');
    if (storedTheme) {
      setTheme(storedTheme);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('theme', theme);
  }, [theme]);

  const [images, setImages] = useState([
    "https://via.placeholder.com/100", // Replace with your image URLs
    "https://via.placeholder.com/100",
  ]);

  const [text, setText] = useState("");
  const [isFocused, setIsFocused] = useState(false); // To track if the div is focused


  const handleRemoveImage = (index) => {
    const updatedImages = images.filter((_, i) => i !== index);
    setImages(updatedImages);
  };

  const handleTextChange = (e) => {
    setText(e.target.innerText.trim());
  };


  const formatUsedCount = (count) => {
    if (count < 100) return count.toString(); // Below 100, show exact count
    if (count < 1000) return Math.floor(count / 50) * 50 + "+"; // 100+, 150+, 200+...
    if (count < 10000) return (count / 1000).toFixed(1).replace(/\.0$/, "") + "K+"; // 1K+, 1.6K+...
    return "10K+"; // Max cap at 10K+
  };

  const handleAddHashtag = (e) => {
    if (e.key === 'Enter' && inputValue.trim()) {
      setHashtagsArray([...hashtagsArray, inputValue.trim()])
      setInputValue('')
    }
  }

  const removeHashtag = (index) => {
    setHashtagsArray(hashtagsArray.filter((_, i) => i !== index))
  }

  const fetchHastags = async (data) => {
    try {
      const response = await searchHastags(data);
      console.log("searchHastags response ==>>", response);
      if (response.statusCode === 200) {

        let dataToSet = response?.data && response?.data?.length ? response?.data : [];
        setSearchedHastagsData(dataToSet)
      }
    } catch (error) {
      console.error("Error uploading photo:", error);
    }
  }


  useEffect(() => {
    if (inputValue) {
      fetchHastags(inputValue);
    }
  }, [inputValue])

  return (
    <>
      <div className={`container-fluid background-main pb-5 theme-switcher ${theme === 'dark' ? 'black-color' : 'light-theme'}`}>
        <h2 className='search-note-h2 pt-5'>Ask</h2>

        <div className={`search-note m-auto mt-4 theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>

          <center>
            {/* <div className='d-flex w-100'> */}
            {/* <div style={{
                marginTop: `8%`,
                paddingLeft: `10%`
              }}>
                <CompletionPercentage percentage={userProfileCompletionPercentage} />
              </div> */}
            {/* <div style={{ width: `72%` }}> */}
            <div>

              <img src={logo} alt="" className='pt-2' />
            </div>
            {/* </div> */}
            <p className={`robert-p pt-2 pb-2 theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}>Hi, {JSON.parse(localStorage.getItem("user")).name} 👋</p>
          </center>
          <center>
            <p className={`robert-p pt-2 pb-2 theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}>Ask anything, and get an answer from a <br /> real human in this world of AI</p>
          </center>
          <div className="px-4 pt-2">
            <div className="border border-gray-300 rounded-lg p-4 ">
              {/* Editable Text Area with Placeholder */}
              {/* {text === "" && !isFocused ?
              <div
                contentEditable
                className={`mb-4 outline-none ${!isFocused && text === "" ? "text-gray-400" : "text-gray-500"
                  }`}
                onInput={handleTextChange}
                onFocus={() => setIsFocused(true)} // Removes placeholder styling when focused
                onBlur={() => setIsFocused(false)} // Restores placeholder styling when blurred
                suppressContentEditableWarning={true}
                style={{ minHeight: "24px" }} // Ensures div visibility even when empty
              >
                {text === "" && !isFocused ? "Ask Anything..." : <></>}
              </div>
: */}
              {/* <Form.Control
              as="textarea"
              value={note}
              onChange={(e) => setNote(e.target.value)}
              placeholder="Ask Anything..."
              style={{ height: '100px' }}
              className={`p-0 border-0 form-input-modal theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}
            /> */}

              <Form.Control
                as="textarea"
                value={note}
                onChange={(e) => setNote(e.target.value)}
                placeholder="Ask Anything..."
                style={{
                  height: '100px',        // Fixed height
                  overflowY: 'scroll',    // Allow scrolling
                  resize: 'none',         // Disable manual resizing
                  scrollbarWidth: 'none', // Firefox
                  msOverflowStyle: 'none', // IE
                }}
                className={`p-0 border-0 form-input-modal  theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}
              >
                <style>
                  {`
      textarea::-webkit-scrollbar {
        width: 0px;  /* Hide scrollbar in Chrome, Safari */
      }
      textarea::-webkit-scrollbar-thumb {
        background: transparent; /* Ensures no thumb is visible */
      }
      textarea::-webkit-scrollbar-track {
        background: transparent; /* Ensures no track is visible */
      }
    `}
                </style>
              </Form.Control>


              {/* } */}

              {/* Image Mapping */}
              {/* <div className="flex space-x-2 mt-2">
                {selectedMediaArr && selectedMediaArr.map((src, index) => (
                  <div
                    key={index}
                    className="relative w-20 h-20 rounded-lg overflow-hidden border border-gray-200"
                  >
                    <img
                      src={src?.value}
                      alt={`Uploaded ${index}`}
                      className="w-full h-full object-cover"
                    />
                    <IconButton
                      size="small"
                      className="absolute top-1 right-1 bg-red-500 text-white rounded-full"
                      onClick={() => handleRemoveImage(index)}
                      style={{ zIndex: 10 }}  // Ensure it's on top
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </div>
                ))}
              </div> */}

              <div className="new-scroll-add" >
                <div className={`d-flex mt-0 overflow-reponsive ${selectedMediaArr && selectedMediaArr.length > 4 ? `overflow-x-scroll` : ''}`}>
                  {selectedMediaArr && selectedMediaArr.map((val, index) => (
                    <div className='m-2' style={{ width: '6rem', minWidth: '6rem', height: '6rem', position: 'relative' }} key={index}>
                      {val.type === 'image' ? (
                        <img src={val.value} alt="Preview" className='object-fit-cover' style={{ borderRadius: '10px', width: '100%', height: '100%' }} onClick={() => handleImageClick(val)} />
                      ) : (
                        <video controls className='object-fit-cover' style={{ borderRadius: '10px', width: '100%', height: '100%' }} onClick={() => handleImageClick(val)}>
                          <source src={val.value} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      )}
                      <div className='absolute ms-5 text-danger p-2' style={{ top: '-7px', right: '-7px', borderRadius: "100px", background: "white", cursor: "pointer" }} onClick={() => setSelectedMediaArr(selectedMediaArr.filter((obj) => obj.key !== val.key))} >

                        <img src={DeleteIcon2} alt="" style={{ width: "10px", cursor: "pointer" }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>

            </div>
            {/* 
            <Form.Control
              as="textarea"
              value={note}
              onChange={(e) => setNote(e.target.value)}
              placeholder="Ask Anything..."
              style={{ height: '100px' }}
              className={`form-input-modal theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}
            /> */}

            {uploadProgress > 0 && (
              <div className="progress mt-3">
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{ width: `${uploadProgress}%` }}
                  aria-valuenow={uploadProgress}
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  {uploadProgress}%
                </div>
              </div>
            )}
<center className='pb-5'>
<div className="button-wrapper mt-5">
      
      <Button
                  component="label"
                  role={undefined}
                  variant="contained"
                  tabIndex={-1}
                  className="button-add-photos"
                >
                  <div className="add-photos-icon">

                    <img src={FileUpload} alt="" className="upload-file-icon" />

                  </div>
                  <p>Add Photos</p>
                  <VisuallyHiddenInput
                    type="file"
                    accept="image/*,video/*"
                    onChange={handleFileChange}
                  />
                </Button>
      <button className="button-search" onClick={() => handleAddOrEditNoteBeforeHashtags()}>Search</button>
    </div>
</center>
            {/* <div className="custom-button-container pb-5 mt-5">
              <div className=" custom-save-draft " onClick={() => handleAddOrEditNote('0')}>
                <p>Save Draft</p>
              </div>
              <div className="custom-add-photos">

                <Button
                  component="label"
                  role={undefined}
                  variant="contained"
                  tabIndex={-1}
                  className="custom-add-photos" 
                >
                  <div className="custom-photo-icon">

                    <img src={FileUpload} alt="" className="upload-file-icon ms-4 ps-3 m-2" />

                  </div>
                  <p>Add Photos</p>
                  <VisuallyHiddenInput
                    type="file"
                    accept="image/*,video/*"
                    onChange={handleFileChange}
                  />
                </Button>
              </div>

           
              <div className=" custom-search" onClick={() => handleAddOrEditNoteBeforeHashtags()}>
                <p>Search</p>
              </div>
            </div> */}
          </div>
        </div>


        <Modal
          open={showModal}
          onClose={() => setShowModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          centered >
          <Box sx={styleModal} className={`inner-shadow p-0 border-0 border-rduis p-4 theme-switcher ${theme === 'dark' ? 'black-color' : 'light-theme'}`}>
            <img src={xmark} alt="" className="x-mark  position-absolute right-4 z-index-999" onClick={() => setShowModal(false)} />
            <div className='d-flex justify-content-center'>
              {selectedMedia && selectedMedia.type === 'image' ? (
                <img src={selectedMedia.value} alt="Full View" className='full-width-image-video' />
              ) : selectedMedia && selectedMedia.type === 'video' ? (
                <video controls className='full-width-image-video' >
                  <source src={selectedMedia.value} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              ) : null}
            </div>
          </Box>
        </Modal>

      </div>
      <div>
        <Modal
          open={showAmountCheckModal}
          // onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className={` ' inner-shadow p-0 border-0 border-rduis p-4 theme-switcher ${theme === 'dark' ? 'black-color' : 'light-theme'}`}>
            <div className="d-grid">
              <div className="d-flex justify-content-center">
                <img src={logo} alt="" className="h-75" />
              </div>
              <p className={`text-center py-2 px-4 theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}>
                You have insufficient funds for your search. First Add funds to continue.
              </p>
            </div>
            <center className='mt-4'>
              <button className='cancel-btn mx-2' onClick={handleClose}>Cancel</button>
              <button className='logout-btn mx-2' onClick={() => navigate('/profile')}>Add payment</button>
            </center>
          </Box>
        </Modal>
      </div>
      <div>
        {/* <Modal
          open={true}
          // onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className={` ' inner-shadow p-0 border-0 border-rduis p-4 theme-switcher ${theme === 'dark' ? 'black-color' : 'light-theme'}`}>
            <div className="d-grid">
              <div className="d-flex justify-content-center">
                <img src={logo} alt="" className="h-75" />
              </div>
              <p className={`text-center py-2 px-4 theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}>
                You have insufficient funds for your search. First Add funds to continue.
              </p>
            </div>
            <center className='mt-4'>
              <button className='cancel-btn mx-2' onClick={handleClose}>Cancel</button>
              <button className='logout-btn mx-2' onClick={() => navigate('/profile')}>Add payment</button>
            </center>
          </Box>
        </Modal> */}


        <Modal
          open={isOpen}
          onClose={() => setIsOpen(false)}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 550,
              bgcolor: 'background.paper',
              boxShadow: 24,
              borderRadius: 2,
              overflow: 'hidden'
            }}
            className="pb-4"
          >
            <Typography
              id="modal-title"
              variant="h6"
              component="div"
              className="text-center text-lg font-bold mb-4 py-3"
              sx={{
                fontWeight: 600,
                background: 'linear-gradient(to right, #f10b80, #830d9a)',
                // WebkitBackgroundClip: 'text',
                color: 'white',
              }}
            >
              Add Hashtags
            </Typography>
            <div className="space-y-4 px-3">
              <div
                className="rounded-4 px-2 py-2 min-h-[100px] flex flex-col gap-16"
                style={{
                  background: '#f3f4f6',
                }}
              >
                <Input
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  onKeyDown={handleAddHashtag}
                  placeholder="#hash"
                  fullWidth
                  disableUnderline
                  className="mb-2"
                  style={{
                    background: '#f3f4f6',
                    borderRadius: '8px',
                    padding: '4px 0',
                  }}
                />
                <div className="flex flex-wrap gap-2">
                  {hashtagsArray.map((tag, index) => (
                    <div
                      key={index}
                      className="flex items-center gap-1 mt-2 rounded-full text-sm"
                      style={{
                        background: 'linear-gradient(to right, #f10b80, #830d9a)',
                        color: 'white',
                        borderRadius: '20px',
                        padding: '10px 20px',
                        position:"relative",
                      }}
                    >
                      #{tag}
                      <button
                        onClick={() => removeHashtag(index)}
                        className="hover:bg-pink-600 rounded-full p-0.5 hastag-remove"
                        style={{
                          background: 'transparent',
                          border: 'none',
                          cursor: 'pointer',
                        }}
                      >
                             <FontAwesomeIcon icon={faX} />

                      </button>
                    </div>
                  ))}
                </div>
              </div>
              <div className="hashtag-list hashtag-list-scrollbar mt-4" style={{
                maxHeight: '100px',
                overflowY: 'scroll'
              }}>
                {searchedHastagsData &&
                  searchedHastagsData
                    .filter((searchedHashtag) =>
                      !hashtagsArray.some(
                        (hashtag) => hashtag.title == searchedHashtag.title
                      )
                    ).map((hashtag, index) => (
                      <div key={index} className="hashtag-item-span-add" style={{ cursor: 'pointer' }}
                        onClick={() => {
                          // setHashtags([...hashtags, { id: hashtag?._id, title: hashtag?.title, isVerified: hashtag?.isVerified }]);
                          setHashtagsArray([...hashtagsArray, hashtag?.title?.trim()])
                          setInputValue("")
                          setSearchedHastagsData([]);
                        }}>
                        {/* <span>#{hashtag?.title}</span> */}
                        <span className="hashtag-item-span ">#{hashtag?.title}{hashtag?.isVerified == 1 ? <img src={WhiteVerify} alt="" /> : ''}</span>

                        {hashtag?.usedCount ?
                          <span
                            className="remove-hashtag-add"
                            style={{
                              height: `25px`,
                              width: `25px`,
                              display: `flex`,
                              justifyContent: `center`,
                              alignItems: `center`,
                              fontSize: '12px'
                            }}
                          // onClick={() => handleRemoveHashtag(index)}
                          >
                            {formatUsedCount(hashtag?.usedCount || 0)}
                          </span>
                          : ''}
                      </div>
                    ))}
              </div>
              <div className="flex justify-center gap-2">
                {/* <Button
                  variant="outlined"
                  onClick={() => setIsOpen(false)}
                  className="px-8"
                  style={{
                    color: '#ec4899',
                    borderColor: '#ec4899',
                  }}
                >
                  Skip
                </Button> */}
                {/* <Button
                  variant="contained"
                  onClick={() => setIsOpen(false)}
                  className="search-btn mt-4"
                  // style={{
                  //   backgroundImage: 'linear-gradient(to right, #ec4899, #8b5cf6)',
                  //   color: '#fff',
                  // }}
                >
                  Search
                </Button> */}
                <button className='hashtag-modal-search-button-skip  mt-4' style={{ backgroundImage: '#fff' }} onClick={() => setIsOpen(false)}>Skip</button>

                <button className='hashtag-modal-search-button mt-4' onClick={() => handleAddOrEditNote('1')}>Search</button>

              </div>
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
}

export default Index;
