import React, { useState, useEffect } from 'react';
import girl from '../../Images/girl.png';
import animationline from '../../Images/animation-line.png';
import animationrating from '../../Images/animation-rating.png';
import animationdone from '../../Images/animation-done.png';
import animationvisitor from '../../Images/animation-visitor.png';
import animationicon from '../../Images/animation-icon.png';
import animation from '../../Images/animation.png';
import WhyChoose from './WhyChoose';
import TrustedUs from './TrustedUs';
import ReadOurBlog from './ReadOurBlog';
import CustomerSaying from './CustomerSaying';
import Subscribe from './Subscribe';
import NavbarLogout from './NavbarLogout'
import { Helmet } from "react-helmet-async";

const Index = () => {
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');
  const token = localStorage.getItem('token');

  useEffect(() => {
    const storedTheme = localStorage.getItem('theme');
    if (storedTheme) {
      setTheme(storedTheme);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('theme', theme);
  }, [theme]);

  return (
    <div>
      <Helmet>
        <title>
        AI helpful for everyone - Ask Human
        {" "}
        </title>
        <meta
          name="description"
          content="Discover how AskHuman AI's advanced AI technology transforms customer support and enhances business efficiency. Explore our solutions and services today.
"
        />
      </Helmet>
      {/* <NavbarLogout /> */}
      <div className={`container-fluid landing-section mb-5 theme-switcher ${theme === 'dark' ? 'black-color' : 'light-theme'}`}>

        <div className="container">
          <div className="row pt-5">
            <div className="col-md-5 build-business pt-5">
              <h2 className={` pt-2 theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}>
                Ask anything - Get answer from real human opinions
              </h2>
              <p className={` pt-2 theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}>
              Discover Real Answers from Real People. Whether aspiring to be a Millionaire, Influencer, Chef, Boxer, Fitness Enthusiast, or tech influencer, AskHuman connects you with the wisdom and experience of those who’ve walked the path you aim to follow.
              </p>
              {/* <a href="mailto:ideiosoft@gmail.com" > <button className="logout-btn mt-4">Contact Us</button>
             </a> */}
                <a href={token ? "/search-note" : "/login"}>
      <button className="logout-btn mt-4">Get a Quote</button>
    </a>
             {/* <a href="/search-note" > <button className="logout-btn mt-4">Get a Quotes</button></a> */}
            </div>
            <div className="col-md-7">
              <img src={girl} alt="" className="animtion-girl" />
              <div className="">
                <img src={animationline} alt="" className="animation-line" id="animation-none" />
                <img src={animationrating} alt="" className="animation-rating" id="animation-none" />
                <img src={animationdone} alt="" className="animation-done" id="animation-none" />
                <img src={animationvisitor} alt="" className="animation-visitor" id="animation-none" />
                <img src={animationicon} alt="" className="animation-icon" id="animation-none" />
                <img src={animation} alt="" className="animation" id="animation-none" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <WhyChoose />
      <TrustedUs />
      <ReadOurBlog />
      <CustomerSaying />
      <Subscribe  />
    </div>
  );
}

export default Index;
