import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles
import axiosInstance from "../../services/axiosInstance"; // Ensure this import path is correct
import verifiedIcon from "../../Images/verified.png"; // Assuming you have the verified icon in this path
import Grid from "@mui/material/Grid";
import NoMore from '../../Images/no-user-found.svg';

// const fetchAllUserList = async (selectedItems) => {
//   // const data = {
//   //   search: "",
//   //   gender: [],
//   //   ethnicity: [],
//   //   zodiac: [],
//   //   minage: "10",
//   //   maxage: "100",
//   //   smoke: [],
//   //   drink: [],
//   //   workout: [],
//   //   bodytype: [],
//   // };

//   const token = localStorage.getItem("token"); // Replace with the actual token if needed

//   try {
//     console.log("Fetching all user list with data:", selectedItems, token);
//     const response = await axiosInstance.post("/user/allUserList", selectedItems, {
//       headers: {
//         accept: "application/json",
//         Authorization: `Bearer ${token}`,
//         "Content-Type": "application/json",
//       },
//     });
//     console.log("User list response:", response);
//     return response.data;
//   } catch (error) {
//     console.error("Error fetching user list:", error);
//     throw new Error("Failed to fetch user list. Please try again later.");
//   }
// };

const UserList = ({ refer, nid, handleSelect, users }) => {
  // const [users, setUsers] = useState(fetchedUsers);


  useEffect(() => {
    // const fetchUsers = async () => {
    //   try {
    //     const data = await fetchAllUserList(selectedItems);
    //     if (data.status === "OK") {
    //       setUsers(data.data.users);
    //     }
    //   } catch (error) {
    //     console.error("Error fetching users:", error);
    //   }
    // };

    // fetchUsers();

    AOS.init({
      duration: 1000, // animation duration in milliseconds
      easing: "ease", // default easing for AOS animations
      offset: 100, // offset (in px) from the original trigger point
      delay: 100, // delay (in ms) for AOS animations
      once: true, // whether animation should happen only once
    });
  }, []);
  
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');
  useEffect(() => {
    const storedTheme = localStorage.getItem('theme');
    if (storedTheme) {
      setTheme(storedTheme);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('theme', theme);
  }, [theme]);

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  return (
    <div className="user-list">
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
        className="mt-4"
      >
        {users && users.length > 0
        ? users.map((user, index) => (
          <Grid item xs={12} sm={4} md={2.4} key={index}>
            <Card
              key={user?._id}
              sx={{ maxWidth: 345 }}
              className={` ' inner-shadow home-card-sw theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme' }`}
              data-aos="fade-up"
              style={{
                height: "288px"
              }}
            >
              <button className="open-now-btnn">
                <Checkbox
                  {...label}
                  defaultChecked={false}
                  color="secondary"
                  style={{ color: "#BB0C8C" }}
                  onChange={(e) => handleSelect(e.target.checked, user?._id)}
                  
                />
              </button>
              <Link to={refer ? `/user-detail-refer/${user?._id}`: `/user-detail/${user?._id}/${nid}`} className="link-decoration">
              <CardMedia
                component="img"
                alt={user?.name}
                height="140"
                className="card-image"
                image={user?.profileImage || "path/to/default/image.png"}
                style={{ height: "170px" }}
              />

              
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="div"
                    className={` ' card_heading d-flex theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme' }`}
                  >
                    
                    {user?.name?.length > 10 ? `${user?.name.substring(0, 10)}...` : user?.name}
                    {user?.videoVerify === 1 && (
                      <span className="mt-2 ms-2">
                        <img src={verifiedIcon} alt="verified" />
                      </span>
                    )}

                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    className={` ' card-pera theme-switcher ${theme === 'dark' ? 'dark-theme-only-white-light' : 'light-theme' }`}
                  >
                    {user?.about?.length > 28 ? `${user?.about?.substring(0, 28)}...` : user?.about}
                  </Typography>
                </CardContent>
              </Link>
            </Card>
          </Grid>
        )
        )
        : <img src={NoMore} alt="" className="no-da mb-4" />
        }
      </Grid>
    </div>
  );
};

export default UserList;
