import React, { useEffect } from "react";

import { Formik, Field, ErrorMessage, Form as FormikForm } from "formik";
import * as Yup from "yup";
import Form from "react-bootstrap/Form";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import image from "../../Images/Group1686558039.png";
import { Link } from "react-router-dom";
// import Form from 'react-bootstrap/Form';
import { useState } from "react";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { styled } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { faPhotoFilm } from "@fortawesome/free-solid-svg-icons";
import InputGroup from "react-bootstrap/InputGroup";
import Range from "../Login/Create-profile/range";
import xmark from "../../Images/x-mark.png";
import { photoUpload, profileComplete, requestVerificationHashtag } from "../../services/index";
import { notifySuccess, notifyError } from "../../toastNotifications";
import Switch, { SwitchProps } from "@mui/material/Switch";
import { BorderAllRounded } from "@mui/icons-material";
import CustomDatePickerWithoutMonth from './CustomDatePickerWithoutMonth';
import App from './EditHashtagComponent';
import {
  format,
  addMonths,
  subMonths,
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  isSameMonth,
  isSameDay,
  differenceInYears,
  setYear,
  getYear,
} from "date-fns";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
export default function BasicModal({ data }) {
  const [open, setOpen] = React.useState(false);
  const [openNestedModal, setOpenNestedModal] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [imageDataUrl, setImageDataUrl] = useState(null);
  const [modelOpenStatus, setModelOpenStatus] = useState(false);
  const [imageError, setImageError] = useState(null);
  const [selectedHashTags, setSelectedHashTags] = useState([]);
  const [hashTagsData, setHashTagsData] = useState([]);

  const handleNestedModalOpen = (data) => {
    setHashTagsData(data)
    setOpenNestedModal(true)
  };
  const handleNestedModalClose = () => {
    setHashTagsData([])
    setOpenNestedModal(false)
  };
  const [formikData, setFormikData] = useState(null);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const zodiacSigns = [
    "Jawan",
    "Munawwar Rangila",
    "Salsabilla",
    "Now Entertainment",
  ];

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .test(
        "notEmptyAfterTrim",
        "Name is required",
        (value) => value.trim() !== ""
      )
      .required("Name is required"),
    about: Yup.string(),
    // .trim()
    // .test(
    //   "notEmptyAfterTrim",
    //   "About is required",
    //   (value) => value.trim() !== ""
    // )
    // .required("About is required"),
    gender: Yup.string()
      .trim()
      .test(
        "notEmptyAfterTrim",
        "Gender is required",
        (value) => value.trim() !== ""
      )
      .required("Gender is required"),
    ethnicity: Yup.string()
      .trim()
      .test(
        "notEmptyAfterTrim",
        "Ethnicity is required",
        (value) => value.trim() !== ""
      )
      .required("Ethnicity is required"),
    zodiac: Yup.string()
      .trim()
      .test(
        "notEmptyAfterTrim",
        "Zodiac is required",
        (value) => value.trim() !== ""
      )
      .required("Zodiac is required"),
    hoursPrice: Yup.number()
      .positive("positive number allowed only")
      .required("Hourly price is required")
      .max(
        10000000,
        "You can set the hourly price up to 10,000,000 dollars only."
      ),
    age: Yup.number().positive("positive number allowed only")
      .min(18, 'Minimum age 18 required')
      .max(72, 'Minimum age 72 allowed')
      .required('Age is required'),

    dob: Yup.string().trim()
      .test('notEmptyAfterTrim', 'Dob is required', value => value.trim() !== '')
      .required("Dob is required"),
    smoke: Yup.string()
      .trim()
      .test(
        "notEmptyAfterTrim",
        "Smoke status is required",
        (value) => value.trim() !== ""
      )
      .required("Smoke status is required"),
    drink: Yup.string()
      .trim()
      .test(
        "notEmptyAfterTrim",
        "Drink status is required",
        (value) => value.trim() !== ""
      )
      .required("Drink status is required"),
    workout: Yup.string()
      .trim()
      .test(
        "notEmptyAfterTrim",
        "Workout status is required",
        (value) => value.trim() !== ""
      )
      .required("Workout status is required"),
    bodytype: Yup.string()
      .trim()
      .test(
        "notEmptyAfterTrim",
        "Bodytype status is required",
        (value) => value.trim() !== ""
      )
      .required("Bodytype status is required"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    console.log("handleSubmit -- values", values);
    const {
      name,
      about,
      gender,
      ethnicity,
      age,
      dob,
      zodiac,
      smoke,
      drink,
      workout,
      bodytype,
      hoursPrice,
    } = values;
    setSubmitting(true);
    if (!imageFile && !formikData?.profileImage) {
      setImageError(true);
      setSubmitting(false);
    } else {
      setImageError(false);
      try {
        console.log("imageFile is here --> ", imageFile);
        const file = imageFile;
        console.log(file, "filefilefilefile");

        if (!file && !formikData?.profileImage) {
          console.error("No file selected");
          notifyError(`Image required`);
          setSubmitting(false);
          return;
        }

        // Create a new FileReader instance
        const newForm = new FormData();
        if (file) {
          const reader = new FileReader();

          // reader.onloadend = async () => {
          const dataURL = imageDataUrl;
          console.log("handleUpload dataURL is here -->", dataURL);

          // Convert data URL to Blob
          const byteString = atob(dataURL.split(",")[1]);
          const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
          const ab = new ArrayBuffer(byteString.length);
          const ia = new Uint8Array(ab);
          for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
          }
          const blob = new Blob([ab], { type: mimeString });

          // Create form data object to send binary data
          console.log("imageFile.name is here --> ", file.name);

          reader.readAsDataURL(file);

          newForm.append("profileImage", blob, file.name);
        } else {
          newForm.append("profileImage", formikData?.profileImage);
        }
        newForm.append("name", name);
        newForm.append("about", about);
        newForm.append("gender", gender);
        newForm.append("ethnicity", ethnicity);
        newForm.append("zodiac", zodiac);
        newForm.append("age", age);
        newForm.append("dob", dob);
        newForm.append("smoke", smoke);
        newForm.append("drink", drink);
        newForm.append("workout", workout);
        newForm.append("bodytype", bodytype);
        newForm.append("hoursPrice", hoursPrice);
        if (selectedHashTags && selectedHashTags.length > 0) {
          newForm.append("hashtags", JSON.stringify(selectedHashTags.map((val) => ({ id: val?.id || val?._id || null, title: val?.title }))));
        }
        console.log("formdata is hereeee ---->>>>", formikData);

        // formData.append("profileImage", blob, file.name);
        try {
          const response = await profileComplete(newForm);
          console.log("profileComplete response ==>>", response);
          if (response.statusCode === 200) {
            notifySuccess(response.message);
            handleClose();
            // setTimeout(() => {
            window.location.reload();
            // }, 2000)
          }
        } catch (error) {
          console.error("Error uploading photo:", error);
          notifyError(error?.response?.data?.message || `Something went wrong`);
          handleClose();
        } finally {
          setSubmitting(false);
        }
        // };

        // Read the file as a binary string
        // Use readAsDataURL to set image preview correctly
      } catch (error) {
        console.error("Error uploading file:", error);
      } finally {
        setSubmitting(false);
      }
    }
  };

  const handleFileChange = async (event) => {
    console.log("working================================here");
    try {
      const file = event.target.files[0];
      setImageFile(file);
      setImageError(false);
      console.log(file, "filefilefilefile");
      if (!file) {
        console.error("No file selected");
        return;
      }
      const reader = new FileReader();
      reader.onloadend = async () => {
        const dataURL = reader.result;
        console.log(dataURL, "dataURL");
        setImageDataUrl(dataURL);
        // Convert data URL to Blob
        const byteString = atob(dataURL.split(",")[1]);
        const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([ab], { type: mimeString });
        // Create form data object to send binary data
        const formData = new FormData();
        formData.append("file", blob, file.name);
        try {
          // Upload the file using the photoUpload function
          const imageUpload = await photoUpload(formData);
          if (imageUpload.statusCode === 200) {
            console.log(imageUpload.data.imageUrl, "imageUpload.data.imageUrl");
            setImageUrl(imageUpload.data.imageUrl);
            setImagePreview(imageUpload.data.imageUrl);
            console.log(imageUrl, "imageUrl");
          }
        } catch (error) {
          console.error("Error uploading image:", error);
        }
      };
      // Read the file as a binary string
      reader.readAsDataURL(file); // Use readAsDataURL to set image preview correctly
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const handleErrorCheck = async () => {
    if (!imageFile && !formikData?.profileImage) {
      setImageError(true);
    } else {
      setImageError(false);
    }
  };

  React.useEffect(() => {
    if (data) {
      console.log("data is here -->", data);
      // const { name, about, gender, ethnicity, zodiac, smoke, drink, workout, bodytype } = data;
      setFormikData(data);
      setSelectedHashTags(
        data?.hashtags && data?.hashtags?.length ? data?.hashtags : []
      );
      // formikProps.setValues({
      //     name,
      //     about,
      //     gender,
      //     ethnicity,
      //     zodiac,
      //     age,
      //     smoke,
      //     drink,
      //     workout,
      //     bodytype,
      //     hoursPrice,
      // });
    }
  }, []);

  const handleSubmitHashtags = (values) => {
    // console.log(`handleSubmitHashtags ===>>>`, values)
    setSelectedHashTags(values);
  };

  const [theme, setTheme] = useState(localStorage.getItem("theme") || "light");



  const handleRequestForVerifyHashtag = async (hashId) => {
    // console.log(`handleRequestForVerifyHashtag`, hashId);
    try {
      const response = await requestVerificationHashtag({ id: hashId });
      if (response.statusCode === 200) {
        notifySuccess(response?.message)
        window.location.reload();
      } else {
        notifyError(response?.message)
      }
    } catch (error) {
      notifyError(error?.response?.data?.message || `Something went wrong`)
    } finally {
      handleNestedModalClose();
      handleClose();
    }
  }
  useEffect(() => {
    const storedTheme = localStorage.getItem("theme");
    if (storedTheme) {
      setTheme(storedTheme);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("theme", theme);
  }, [theme]);

  const calculateAge = (dob) => {
    if (!dob) return "";
    return differenceInYears(new Date(), dob);
  };

  return (
    <div>
      <FontAwesomeIcon
        icon={faPen}
        onClick={handleOpen}
        style={{ cursor: "pointer" }}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
          className={` ' inner-shadow p-0 border-0 border-rduis p-4 theme-switcher ${theme === "dark" ? "black-color" : "light-theme"
            }`}
        >
          <img src={xmark} alt="" className="x-mark" onClick={handleClose} />{" "}
          <h5 className="text-center">Update Profile</h5>
          <div className="ask-to-sign ask-to-sign-scroll px-5  ">
            <Formik
              initialValues={
                !formikData
                  ? {
                    name: "",
                    about: "",
                    gender: "",
                    ethnicity: "",
                    zodiac: "",
                    age: 0,
                    dob: "",
                    smoke: "",
                    drink: "",
                    workout: "",
                    bodytype: "",
                    hoursPrice: "",
                  }
                  : {
                    name: formikData?.name || "",
                    about: formikData?.about || "",
                    gender: formikData?.gender.toString() || "",
                    ethnicity: formikData?.ethnicity || "",
                    zodiac: formikData?.zodiac || "",
                    age: formikData?.age ? parseInt(formikData?.age) : 0,
                    dob: formikData?.dob ? format(new Date(formikData?.dob), "yyyy-MM-dd") : '',
                    smoke: formikData?.smoke || "",
                    drink: formikData?.drink || "",
                    workout: formikData?.workout || "",
                    bodytype: formikData?.bodytype || "",
                    hoursPrice: formikData?.hoursPrice || "",
                  }
              }
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({
                formikProps,
                isSubmitting,
                values,
                setFieldValue,
                errors,
              }) => (
                <FormikForm className="mt-4 d-grid gap-2">
                  <Form.Label
                    htmlFor="profileImage"
                    className={` ' form-label mt-5 theme-switcher ${theme === "dark" ? "dark-theme-only-white" : "light-theme"
                      }`}
                  >
                    Upload Profile
                  </Form.Label>
                  <br />
                  <input
                    type="file"
                    id="profileImage"
                    name="profileImage"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                  />
                  <label htmlFor="profileImage">
                    {imagePreview || formikData?.profileImage ? (
                      <img
                        style={{ marginTop: "-25px" }}
                        src={imagePreview || formikData?.profileImage}
                        alt="Preview"
                        width="100"
                        className="side-bar-profile object-fit-cover"
                      />
                    ) : (
                      // {imagePreview || formData?.profileImage ? (
                      //     <img style={{ marginTop: '-25px' }} src={imagePreview} alt="Preview" className="side-bar-profile object-fit-cover" />
                      <Button
                        component="span"
                        role={undefined}
                        variant="contained"
                        tabIndex={-1}
                        className="upload-file"
                        style={{ marginTop: "-25px" }}
                      >
                        <FontAwesomeIcon
                          icon={faPhotoFilm}
                          className="upload-file-icon mt-4 pt-1"
                        />
                      </Button>
                    )}
                  </label>
                  {imageError ? (
                    <span className="text-danger">Profile image required</span>
                  ) : (
                    ""
                  )}
                  {/* Conditionally render imagePreview */}

                  <div className="row">
                    <div className="col-md-6">
                      <Form.Group>
                        <Form.Label
                          className={`form-label theme-switcher ${theme === "dark"
                            ? "dark-theme-only-white"
                            : "light-theme"
                            }`}
                        >
                          User Name
                        </Form.Label>
                        {/* <Field
                          type="text"
                          name="name"
                          placeholder="Enter Name"
                          className={` ' form-control form-input theme-switcher ${
                            theme === "dark"
                              ? "dark-theme-only-color"
                              : "light-theme"
                          }`}
                        /> */}
                        <Field
                          autoFocus={true}
                          type="text"
                          name="name"
                          placeholder="Enter Name"
                          className={`form-control form-input theme-switcher ${theme === "dark"
                            ? "dark-theme-only-color"
                            : "light-theme"
                            }`}
                          // pattern="\d{3,30}"
                          minLength="3"
                          maxLength="30"
                          title="Please enter a number between 3 and 30 digits."
                        />

                        <ErrorMessage
                          name="name"
                          component="div"
                          className="text-danger"
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group>
                        <Form.Label
                          className={`form-label mt-2 theme-switcher ${theme === "dark"
                            ? "dark-theme-only-white"
                            : "light-theme"
                            }`}
                        >
                          Select Gender
                        </Form.Label>
                        <div className="position-relative">
                          <Field
                            as="select"
                            name="gender"
                            className={`form-control form-input theme-switcher ${theme === "dark"
                              ? "dark-theme-only-color"
                              : "light-theme"
                              }`}
                          >
                            <option value="">Select Gender</option>
                            <option value="0">Male</option>
                            <option value="1">Female</option>
                            <option value="2">TS</option>
                          </Field>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-chevron-down position-absolute end-0 top-50 me-4 translate-middle-y"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fillRule="evenodd"
                              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                            />
                          </svg>
                        </div>
                        <ErrorMessage
                          name="gender"
                          component="div"
                          className="text-danger"
                        />
                      </Form.Group>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <Form.Group>
                        <Form.Label
                          className={`form-label mt-2 theme-switcher ${theme === "dark"
                            ? "dark-theme-only-white"
                            : "light-theme"
                            }`}
                        >
                          Date of birth
                        </Form.Label>
                        {/* <CustomDatePickerWithoutMonth /> */}

                        <CustomDatePickerWithoutMonth value={formikData?.dob ? new Date(formikData.dob) : null}
                          onChange={(e) => {
                            let calculatedAge = calculateAge(e) || 0;
                            let yyyymmdd = e ? format(e, "yyyy-MM-dd") : '';

                            // console.log(`Calculated age ${calculatedAge}`);
                            setFieldValue('age', calculatedAge)
                            setFieldValue('dob', yyyymmdd)

                          }} />
                        <ErrorMessage
                          name="dob"
                          component="div"
                          className="text-danger"
                        />
                        <ErrorMessage
                          name="age"
                          component="div"
                          className="text-danger"
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group>
                        <Form.Label
                          className={`form-label mt-2 theme-switcher ${theme === "dark"
                            ? "dark-theme-only-white"
                            : "light-theme"
                            }`}
                        >
                          Select Zodiac
                        </Form.Label>
                        <div className="position-relative">
                          <Field
                            as="select"
                            name="zodiac"
                            className={`form-control form-input theme-switcher ${theme === "dark"
                              ? "dark-theme-only-color"
                              : "light-theme"
                              }`}
                          >
                            <option value="">Select Zodiac</option>
                            {zodiacSigns.map((sign, index) => (
                              <option key={index} value={sign}>
                                {sign}
                              </option>
                            ))}
                          </Field>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-chevron-down position-absolute end-0 me-4 top-50 translate-middle-y"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fillRule="evenodd"
                              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                            />
                          </svg>
                        </div>
                        <ErrorMessage
                          name="zodiac"
                          component="div"
                          className="text-danger"
                        />
                      </Form.Group>
                    </div>
                  </div>

                  {/* <Form.Group>
                    <Form.Label
                      className={` ' form-label mt-2 theme-switcher ${
                        theme === "dark"
                          ? "dark-theme-only-white"
                          : "light-theme"
                      }`}
                    >
                      Description
                    </Form.Label>
                    <Field
                      as="textarea"
                      name="about"
                      placeholder="Tell something about you"
                      className={` ' border-reduis-null form-control fillter-text-bar form-input theme-switcher ${
                        theme === "dark"
                          ? "dark-theme-only-color"
                          : "light-theme"
                      }`}
                      style={{ borderRadius: "0px !important" }}
                      maxLength={600}
                    />
                    <ErrorMessage
                      name="about"
                      component="div"
                      className="text-danger"
                    />
                  </Form.Group> */}

                  {/* <Form.Group>
                    <Form.Label
                      className={` ' form-label mt-3 theme-switcher ${
                        theme === "dark"
                          ? "dark-theme-only-white"
                          : "light-theme"
                      }`}
                    >
                      Select Age ({values.age})
                    </Form.Label>
                    <Field
                      // type="number"
                      type="hidden"
                      name="age"
                      placeholder="Enter Age"
                      className={` ' form-control form-input theme-switcher ${
                        theme === "dark"
                          ? "dark-theme-only-color"
                          : "light-theme"
                      }`}
                    />
                    <Range
                      value={values.age}
                      onChange={(val) => setFieldValue("age", val)}
                    />
                    <ErrorMessage
                      name="age"
                      component="div"
                      className="text-danger"
                      style={{ marginTop: "-25px" }}
                    />
                  </Form.Group> */}

                  <div className="row">
                    <div className="col-md-6">
                      <Form.Group>
                        <Form.Label
                          className={` ' form-label mt-2 theme-switcher ${theme === "dark"
                            ? "dark-theme-only-white"
                            : "light-theme"
                            }`}
                        >
                          Select Ethnicity
                        </Form.Label>
                        <div className="position-relative">
                          <Field
                            as="select"
                            name="ethnicity"
                            className={` ' form-control form-input theme-switcher ${theme === "dark"
                              ? "dark-theme-only-color"
                              : "light-theme"
                              }`}
                          >
                            <option value="">Select Ethnicity</option>
                            <option value="White">White</option>
                            <option value="Black">Black</option>
                            <option value="Asian">Asian</option>
                            <option value="Indian">Indian</option>
                            <option value="Hispanic">Hispanic</option>
                            <option value="Middle Eastern">
                              Middle Eastern
                            </option>
                            <option value="Other">Other</option>
                          </Field>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-chevron-down position-absolute end-0 top-50 me-4 translate-middle-y"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fillRule="evenodd"
                              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                            />
                          </svg>
                        </div>
                        <ErrorMessage
                          name="ethnicity"
                          component="div"
                          className="text-danger"
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group>
                        <Form.Label
                          className={`form-label mt-3 theme-switcher ${theme === "dark"
                            ? "dark-theme-only-white"
                            : "light-theme"
                            }`}
                        >
                          Do you Drink?
                        </Form.Label>
                        <div className="position-relative">
                          <Field
                            as="select"
                            name="drink"
                            className={`form-control form-input theme-switcher ${theme === "dark"
                              ? "dark-theme-only-color"
                              : "light-theme"
                              }`}
                          >
                            <option value="">Select Option</option>
                            <option value="No">No</option>
                            <option value="Socially">Socially</option>
                            <option value="Regularly">Regularly</option>
                          </Field>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-chevron-down position-absolute end-0 me-4 top-50 translate-middle-y"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fillRule="evenodd"
                              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                            />
                          </svg>
                        </div>
                        <ErrorMessage
                          name="drink"
                          component="div"
                          className="text-danger"
                        />
                      </Form.Group>
                    </div>
                  </div>
                  {/* <div className='row'>
                                        <div className='col-md-6'>

                                        </div>
                                        <div className='col-md-6'>

                                        </div>
                                    </div> */}
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Group>
                        <Form.Label
                          className={`form-label mt-3 theme-switcher ${theme === "dark"
                            ? "dark-theme-only-white"
                            : "light-theme"
                            }`}
                        >
                          Do you Smoke?
                        </Form.Label>
                        <div className="position-relative">
                          <Field
                            as="select"
                            name="smoke"
                            className={`form-control form-input theme-switcher ${theme === "dark"
                              ? "dark-theme-only-color"
                              : "light-theme"
                              }`}
                          >
                            <option value="">Select Option</option>
                            <option value="No">No</option>
                            <option value="Occasionally">Occasionally</option>
                            <option value="Regularly">Regularly</option>
                          </Field>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-chevron-down position-absolute end-0 me-4 top-50 translate-middle-y"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fillRule="evenodd"
                              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                            />
                          </svg>
                        </div>
                        <ErrorMessage
                          name="smoke"
                          component="div"
                          className="text-danger"
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label
                          className={`form-label mt-3 theme-switcher ${theme === "dark"
                            ? "dark-theme-only-white"
                            : "light-theme"
                            }`}
                        >
                          Do you Workout?
                        </Form.Label>
                        <div className="position-relative">
                          <Field
                            as="select"
                            name="workout"
                            className={`form-control form-input theme-switcher ${theme === "dark"
                              ? "dark-theme-only-color"
                              : "light-theme"
                              }`}
                          >
                            <option value="">Select Option</option>
                            <option value="Yoga">Yoga</option>
                            <option value="Long Walk">Long Walk</option>
                            <option value="Boxing">Boxing</option>
                            <option value="Strength">Strength</option>
                          </Field>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-chevron-down position-absolute end-0 me-4 top-50 translate-middle-y"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fillRule="evenodd"
                              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                            />
                          </svg>
                        </div>
                        <ErrorMessage
                          name="workout"
                          component="div"
                          className="text-danger"
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label
                          className={` ' form-label w-100 mt-3  theme-switcher ${theme === "dark"
                            ? "dark-theme-only-white"
                            : "light-theme"
                            }`}
                        >
                          Add Hashtags{" "}
                          <span
                            className="form-label-verify-hashtags"
                            onClick={() => handleNestedModalOpen(selectedHashTags)}
                          >
                            Verify Hashtags
                          </span>
                        </Form.Label>
                        <App
                          handleSubmitHashtags={handleSubmitHashtags}
                          initialHashtags={selectedHashTags.map((val) => ({ id: val?._id, title: val?.title, isVerified: val?.isVerified || 0 }))}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group>
                        <Form.Label
                          className={`form-label mt-3 theme-switcher ${theme === "dark"
                            ? "dark-theme-only-white"
                            : "light-theme"
                            }`}
                        >
                          Select Bodytype
                        </Form.Label>
                        <div className="position-relative">
                          <Field
                            as="select"
                            name="bodytype"
                            className={`form-control form-input theme-switcher ${theme === "dark"
                              ? "dark-theme-only-color"
                              : "light-theme"
                              }`}
                          >
                            <option value="">Select Bodytype</option>
                            <option value="Slim">Slim</option>
                            <option value="Fit">Fit</option>
                            <option value="Muscular">Muscular</option>
                            <option value="Average">Average</option>
                            <option value="Curvy">Curvy</option>
                          </Field>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-chevron-down position-absolute end-0 me-4 top-50 translate-middle-y"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fillRule="evenodd"
                              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                            />
                          </svg>
                        </div>
                        <ErrorMessage
                          name="bodytype"
                          component="div"
                          className="text-danger"
                        />
                      </Form.Group>

                      <Form.Group>
                        <Form.Label
                          className={` ' form-label mt-3 theme-switcher ${theme === "dark"
                            ? "dark-theme-only-white"
                            : "light-theme"
                            }`}
                        >
                          Hourly Price ($)
                        </Form.Label>
                        <Field
                          type="number"
                          name="hoursPrice"
                          placeholder="Enter Hourly Price"
                          className={`form-control form-input theme-switcher ${theme === "dark"
                            ? "dark-theme-only-color"
                            : "light-theme"
                            }`}
                          onWheel={(e) => e.target.blur()}
                          onInput={(e) => {
                            if (e.target.value.length > 8) {
                              e.target.value = e.target.value.slice(0, 8);
                            }
                          }}
                        />

                        <ErrorMessage
                          name="hoursPrice"
                          component="div"
                          className="text-danger"
                        />
                      </Form.Group>
                    </div>
                  </div>

                  {isSubmitting ? (
                    <button
                      type="submit"
                      className="sign-in-btn my-4"
                      disabled={true}
                    >
                      Saving Changes...
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="sign-in-btn my-4"
                      disabled={isSubmitting}
                      onClick={handleErrorCheck}
                    >
                      Update profile
                    </button>
                  )}
                </FormikForm>
              )}
            </Formik>
          </div>
          {/* <button className="search-btn w-100 mt-4"  >Update profile</button> */}
          <Modal
            open={openNestedModal}
            onClose={handleNestedModalClose}
            aria-labelledby="nested-modal-title"
            aria-describedby="nested-modal-description"
          >
            <Box
              sx={style}
              className={` ' inner-shadow p-0 border-0 border-rduis p-4 theme-switcher ${theme === "dark" ? "black-color" : "light-theme"
                }`}
            >
              <img
                src={xmark}
                alt=""
                className="x-mark"
                onClick={handleNestedModalClose}
              />{" "}
              <h5 className="text-center">Verify your hashtags</h5>
              <div className="row mt-4">

                {hashTagsData && hashTagsData?.length > 0
                  ? hashTagsData.map((val) => {
                    // console.log(`val`, val)
                    let id = val?.id || "";
                    let status = val?.isVerified || 0;
                    let title = val?.title || "notfound";
                    return (<div className="col-lg-6 col-md-12 mt-3 ">
                      <div className="card-verify">
                        <span className="label-verify">{`#${title}`}</span>
                        {
                          status == 0
                            ? <button className="pending-verify">
                              <span>Pending</span>
                            </button>
                            : status == 1
                              ? <button className="verified-verify">
                                <span>Verified</span>
                              </button>
                              : status == 2
                                ? <button onClick={() => handleRequestForVerifyHashtag(id)} className="send-request-verify">
                                  <span className="send-request-verify-span">
                                    Send request
                                  </span>
                                </button> : ''}
                      </div>
                    </div>)
                  }) : ''}
                {/* <div className="col-lg-6 col-md-12 mt-3 ">
                  <div className="card-verify">
                    <span className="label-verify">#Camera</span>
                    <button className=" pending-verify">
                      <span>Pending</span>
                    </button>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 mt-3">
                  <div className="card-verify">
                    <span className="label-verify">#Sports</span>
                    <button className=" send-request-verify">
                      <span className="send-request-verify-span">
                        Send request
                      </span>
                    </button>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 mt-3">
                  <div className="card-verify">
                    <span className="label-verify">#Eventstyling</span>
                    <button className=" pending-verify">
                      <span>Pending</span>
                    </button>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 mt-3">
                  <div className="card-verify">
                    <span className="label-verify">#Travels</span>
                    <button className=" pending-verify">
                      <span>Pending</span>
                    </button>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 mt-3">
                  <div className="card-verify">
                    <span className="label-verify">#Contents</span>
                    <button className=" send-request-verify">
                      <span className="send-request-verify-span">
                        Send request
                      </span>
                    </button>
                  </div>
                </div> */}

              </div>
            </Box>
          </Modal>
        </Box>
      </Modal>
    </div>
  );
}
