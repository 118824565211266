import React, { useState, useEffect } from 'react';
import logo from '../../../Images/logo-mini.png';
import OTPDigit from './otpdigit'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { forgetPasswordOtpVerify, forgotPassword, otpResendToPhoneVerification, phoneVerification, verifyPhoneNumber } from '../../../services/api/authApi';
import { notifySuccess, notifyError } from "../../../toastNotifications";
import { otpResendToPhoneChange } from '../../../services';
import NavbarSignFlow from '../../Login/NavbarSignFlow'

function Index() {
  const { cred } = useParams();
  const navigate = useNavigate();
  const [filledOtp, setFilledOtp] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [timeLeft, setTimeLeft] = useState(119); // 119 seconds = 1 minute 59 seconds

  //Submit Handlers //

  const handleForgotPassVerification = async () => {
    setIsSubmitting(true);
    let data = {
      emailOrPhone: cred,
      otp: filledOtp.join(""),
    }
    try {
      const response = await forgetPasswordOtpVerify(data);
      console.log("forgetPasswordOtpVerify response is here -->", response);
      if (response.statusCode === 200) {
        notifySuccess(response.message);
        localStorage.setItem("token", response.data.userDetails.token);
        navigate("/set-password");
      }
    } catch (error) {
      console.error("Error in forgetPasswordOtpVerify :", error);
      notifyError(error?.response?.data?.message || `Something went wrong`);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleVerification = async () => {
    setIsSubmitting(true);
    let data = {
      otp: filledOtp.join(""),
      mobile: cred
    }
    try {
      const response = await phoneVerification(data);
      console.log("phoneVerification response is here -->", response);
      if (response.statusCode === 200) {
        // notifySuccess(response.message);
        // localStorage.setItem("token", response.data.userDetails.token);


        // navigate("/create-profile");
        window.location.href = "/home";
      }
    } catch (error) {
      console.error("Error in phoneVerification :", error);
      notifyError(error?.response?.data?.message || `Something went wrong`);
    } finally {
      setIsSubmitting(false);
    }
    // }
  };


  const handleVerificationPhoneChange = async () => {
    setIsSubmitting(true);
    let data = {
      emailOrPhone: cred,
      otp: filledOtp.join(""),
    }
    try {
      const response = await verifyPhoneNumber(data);
      console.log("verifyPhoneNumber response is here -->", response);
      if (response.statusCode === 200) {
        notifySuccess(response?.message);
        navigate("/profile");
        // window.location.href('/profile');
      }
    } catch (error) {
      console.error("Error in verifyPhoneNumber :", error);
      notifyError(error?.response?.data?.message || `Something went wrong`);
    } finally {
      setIsSubmitting(false);
    }
  }


  const handleFillOtp = (val) => {
    setFilledOtp(val);
  }



  // Resend Functions //

  const handleResendPhoneOtp = async () => {
    if (timeLeft === 0) {
      try {
        const response = await otpResendToPhoneVerification();
        console.log("otpResendToPhoneVerification response is here -->", response);
        if (response.statusCode === 200) {
          notifySuccess(response.message);
          localStorage.setItem("mobileOtp", response.data.otp);
          setTimeLeft(119)
          // window.location.reload();
        }
      } catch (error) {
        console.error("Error in otpResendToPhoneVerification :", error);
        notifyError(error?.response?.data?.message || `Something went wrong`)
      }
    }
  }

  const handleResend = async () => {
    if (timeLeft === 0) {
      if (!cred || cred.trim() === "") {
        notifyError(`Email or phone number required`)
      } else {
        let data = {
          emailOrPhone: cred
        }
        try {
          const response = await forgotPassword(data);
          console.log("forgotPassword response is here -->", response);
          if (response.statusCode === 200) {
            notifySuccess(response.message);
            setTimeLeft(119)
          }
        } catch (error) {
          console.error("Error in forgotPassword :", error);
          notifyError(error?.response?.data?.message || `Something went wrong`)
        }
      }
    }
  }


  const handleResendPhoneChangeOtp = async () => {
    console.log(timeLeft)
    if (timeLeft === 0) {
      try {
        const response = await otpResendToPhoneChange();
        console.log("otpResendToPhoneChange response is here -->", response);
        if (response.statusCode === 200) {
          notifySuccess(response.message);
          localStorage.setItem("mobileOtp", response.data.otp);
          setTimeLeft(119);
        }
      } catch (error) {
        console.error("Error in otpResendToPhoneChange :", error);
        notifyError(error?.response?.data?.message || `Something went wrong`)
      }
    }
  }

  // Function to format seconds into "mm:ss" format
  const formatTime = (seconds) => {
    const min = Math.floor(seconds / 60);
    const sec = seconds % 60;
    return `0${min}:${sec < 10 ? '0' : ''}${sec}`;
  };

  useEffect(() => {
    if (timeLeft === 0) return;
    const timer = setInterval(() => {
      setTimeLeft(prevTime => prevTime - 1);
    }, 1000);
    return () => clearInterval(timer);
  }, [timeLeft]);

  // useEffect(() => {
  //   if (cred && window.location.pathname.includes("/otp-verification-phone") && localStorage.getItem('mobileOtp')) {
  //     notifySuccess(`Your verification otp is ${localStorage.getItem('mobileOtp')}`)
  //   }
  // }, []);

  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');
  useEffect(() => {
    const storedTheme = localStorage.getItem('theme');
    if (storedTheme) {
      setTheme(storedTheme);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('theme', theme);
  }, [theme]);

  return (
    <div>
      <NavbarSignFlow />
      <div className={` ' container-fluid theme-switcher ${theme === 'dark' ? 'black-color' : 'light-theme'}`} >
        <div className="row">
          <div className="col-md-7 login-left-section d-flex px-5">
            <div className="justify-content-center align-self-center">
              <h2>International ask Question with Ask Human!</h2>
              <p>Enter the verification code we just sent on your email address.</p>
            </div>
          </div>
          <div className={` ' col-md-5 login-right-section m-auto theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}>
            {/* <p className='pt-4 px-4 login-right-section-p'>Don't have an account?
              <span>
                <b>
                  <Link to="/sign-up" className={` ' forgot-password text-dark theme-switcher ${theme === 'dark' ? 'dark-mode-pink-color' : 'light-theme'}`}> Signup</Link>
                </b>
              </span>
            </p> */}
            <div className="ask-to-sign login-right-section-flex px-5 ">
              <h2 className='d-flex pb-4'>OTP Verification <img src={logo} alt="" /></h2>
              <OTPDigit handleFillOtp={handleFillOtp} />
              {isSubmitting
                ? <button className='sign-in-btn' disabled>Verifying...</button>
                : <button className='sign-in-btn'
                  onClick={
                    window.location.pathname.includes("/otp-verification-mail")
                      ? handleForgotPassVerification
                      : window.location.pathname.includes("/otp-verification-phone")
                        ? handleVerification
                        : window.location.pathname.includes("/otp-verification-change-change")
                          ? handleVerificationPhoneChange
                          : ''
                  } disabled={isSubmitting}>Verify</button>}

              {timeLeft && timeLeft > 0
                ? ''
                : <> <p className={` ' otp-span mt-4 theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}>Didn't receive OTP yet?</p>
                  <p className='otp-span '>
                    <span className='forgot-password' style={{ cursor: 'pointer', textDecoration: 'underline' }}
                      onClick={
                        window.location.pathname.includes("/otp-verification-phone")
                          ? handleResendPhoneOtp
                          : window.location.pathname.includes("/otp-verification-mail")
                            ? handleResend
                            : window.location.pathname.includes("/otp-verification-change-change")
                              ? handleResendPhoneChangeOtp
                              : ''}>
                      Resend OTP
                    </span>
                  </p></>
              }

              {timeLeft && timeLeft > 0
                ? <p className={`pt-2 otp-span theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}>{formatTime(timeLeft)}</p>
                : ''}
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}

export default Index;
