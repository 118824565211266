import React, { useEffect, useRef, useState } from "react";
import logo from "../../Images/logo-mini.png";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Button from "@mui/material/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhotoFilm } from "@fortawesome/free-solid-svg-icons";
import { styled } from "@mui/material/styles";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import fillter from '../../Images/fillter.svg';
import HomeCard from './HomeCard';
import { experimentalStyled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Fillter from './Fillter';
import { notifyError, notifySuccess } from "../../toastNotifications";
import { inviteMultipleUsers, inviteSingleUser, referInvitation } from "../../services/api/contractApi";
import { getAllUserList } from "../../services";
import { X } from 'lucide-react'
import { faX } from '@fortawesome/free-solid-svg-icons';


function Index() {
  const location = useLocation();

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const { id, senderId } = useParams();
  const navigate = useNavigate();

  const [inviteIds, setInviteIds] = useState([]);
  const [fetchedUsers, setFetchedUsers] = useState([]);

  const getHashtagsArrayFromLocalStorage = () => {
    let savedNoteHashtags = localStorage.getItem('saved-note-hashtags');
    try {
      savedNoteHashtags = JSON.parse(savedNoteHashtags);
      if (!Array.isArray(savedNoteHashtags) || !savedNoteHashtags.every(item => typeof item === 'string')) {
        savedNoteHashtags = [];
      }
    } catch {
      savedNoteHashtags = [];
    }
    return savedNoteHashtags;
  }

  const [hashtagsArray, setHashtagsArray] = useState(getHashtagsArrayFromLocalStorage());


  const handleSelect = (status, id) => {
    if (status) {
      if (inviteIds.length < 17) {
        setInviteIds([...inviteIds, id])
      } else {
        notifyError("Max 17 members allowed")
      }
    } else {
      setInviteIds(inviteIds.filter((val) => val !== id))
    }
  }

  const sendInviteHandler = async () => {
    console.log("inviteIds", inviteIds);

    let single = inviteIds.length === 1 ? true : false;
    if (single) {
      console.log("invite single user");
      let data = {
        inviteId: inviteIds[0],
        notesId: id
      }
      try {
        const response = await inviteSingleUser(data);
        console.log("invite to single user response is here -->", response);
        if (response.statusCode === 200) {
          notifySuccess(response.message);
          navigate('/search-note')
        }
      } catch (error) {
        localStorage.removeItem("saved-note");
        localStorage.removeItem("saved-note-hashtags");

        console.error("Error in inviting a single user :", error);
        notifyError(error?.response?.data?.message || `Something went wrong`);
        if (error?.response?.data?.message.toLowerCase() == `you have insufficient funds in your wallet`) {
          navigate('/profile')
        }
      } finally {
        localStorage.removeItem("saved-note");
        localStorage.removeItem("saved-note-hashtags");

      }
    } else {
      console.log("invite multiple users");
      let data = {
        inviteId: inviteIds,
        notesId: id
      }
      try {
        const response = await inviteMultipleUsers(data);
        console.log("invite to multiple users response is here -->", response);
        if (response.statusCode === 200) {
          notifySuccess(response.message);
          navigate('/search-note')
        }
      } catch (error) {
        localStorage.removeItem("saved-note");
        localStorage.removeItem("saved-note-hashtags");

        console.error("Error in inviting a multiple users :", error);
        notifyError(error?.response?.data?.message || `Something went wrong`)
        if (error?.response?.data?.message.toLowerCase() == `you have insufficient funds in your wallet`) {
          navigate('/profile')
        }
      } finally {
        localStorage.removeItem("saved-note");
        localStorage.removeItem("saved-note-hashtags");

      }
    }
  }


  const [selectedItems, setSelectedItems] = useState({
    search: "",
    userId: senderId || "",
    gender: [],
    ethnicity: [],
    zodiac: [],
    minage: "18",
    maxage: "72",
    smoke: [],
    drink: [],
    workout: [],
    bodytype: [],
  });

  const handleClearFilter = () => {
    // setSelectedItems({
    //   search: "",
    // userId: senderId || "",
    // gender: [],
    // ethnicity: [],
    // zodiac: [],
    // minage: "10",
    // maxage: "100",
    // smoke: [],
    // drink: [],
    // workout: [],
    // bodytype: [],
    // })
    window.location.reload();
  }
  // const handleFilterAddRemove = (val) => {
  //   console.log("handleFilterAddRemove -- val", val);
  //   const { category, value } = val;
  //   selectedItems[`${category}`].push(value);
  //   getUsers();
  //   // console.log("selectedItems--gender", selectedItems[`gender`].push('2'));
  //   // console.log("handleFilterAddRemove -- selectedItems -- gender", selectedItems[`gender`]);

  //   // setSelectedItems({ ...selectedItems, [`${category}`]: [value] })
  // }
  const handleFilterAddRemove = (val) => {
    console.log("handleFilterAddRemove -- val", val);
    const { category, filteredValue } = val;

    setSelectedItems(prevState => {
      // Clone the previous state
      const updatedItems = { ...prevState };

      // If category is a multi-select (array), handle add/remove logic
      if (Array.isArray(updatedItems[category])) {
        if (updatedItems[category].includes(filteredValue)) {
          // If value exists, remove it
          updatedItems[category] = updatedItems[category].filter(item => item !== filteredValue);
        } else {
          // If value doesn't exist, add it
          updatedItems[category].push(filteredValue);
        }
      } else {
        // If not an array, just set the value
        updatedItems[category] = filteredValue;
      }

      // Return the updated state
      console.log("updatedItems", updatedItems)
      return updatedItems;
    });

    // Call getUsers after updating the state
    // getUsers();
  };

  const removeHashtag = (index) => {
    let remainedItems = hashtagsArray.filter((_, i) => i !== index);
    setHashtagsArray(remainedItems)

    localStorage.setItem("saved-note-hashtags", JSON.stringify(remainedItems && remainedItems.length > 0 ? remainedItems : []));

  }

  const getUsers = async (val) => {
    setFetchedUsers([]);
    try {

      const response = await getAllUserList({ ...val, hashtags: hashtagsArray });
      console.log("getAllUserList response is here -->", response);
      if (response.statusCode === 200) {
        setFetchedUsers(response.data.users || []);
      }
    } catch (error) {
      setFetchedUsers([]);
      console.error("Error in getAllUserList :", error);
      notifyError(error?.response?.data?.message || `Something went wrong`)
    }
  }

  const handleRefer = async () => {
    let notification = JSON.parse(localStorage.getItem("notification"));
    console.log("notification", notification)
    if (!notification) {
      notifyError(`Something went wrong`);
      navigate(-1);
    } else {
      let data = {
        ...notification,
        referTo: inviteIds || []
      }

      try {
        const response = await referInvitation(data);
        console.log("referInvitation response is here -->", response);
        if (response.statusCode === 200) {
          notifySuccess(response.message);
          localStorage.removeItem('notification');
          localStorage.removeItem("saved-note");
          localStorage.removeItem("saved-note-hashtags");

          navigate('/search-note')
          // setContractData(response.data);
        }
      } catch (error) {
        console.error("Error in referInvitation :", error);
        notifyError(error?.response?.data?.message || `Something went wrong`)
        // navigate(`/profile`)
      }
    }
  }

  useEffect(() => {
    getUsers(selectedItems);
  }, [selectedItems, hashtagsArray]);

  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');
  useEffect(() => {
    const storedTheme = localStorage.getItem('theme');
    if (storedTheme) {
      setTheme(storedTheme);
    }
  }, []);

  useEffect(() => {
    return () => {
      // localStorage.removeItem('notification');
      // localStorage.removeItem("saved-note");
    };
  }, [location]);

  const debounceTimer = useRef(null);


  const handleAgeSelect = (newValue) => {
    console.log("newValue", newValue);

    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }

    debounceTimer.current = setTimeout(() => {
      setSelectedItems({
        ...selectedItems,
        minage: newValue[0].toString(),
        maxage: newValue[1].toString()
      });
    }, 1000);
  };


  const [isUserListFilterDivPresent, setIsUserListFilterDivPresent] = useState(false);

  // useEffect(() => {
  //   // Function to check if the element is present
  //   const checkElementPresence = () => {
  //     const element = document.getElementById('user-list-filter-div');
  //     if (element) {
  //       setIsUserListFilterDivPresent(true);
  //     } else {
  //       setIsUserListFilterDivPresent(false);
  //     }
  //   };
  //   checkElementPresence();
  // }, []);

  console.log("isUserListFilterDivPresent", isUserListFilterDivPresent)
  // useEffect(() => {

  //     const debounceTimer = setTimeout(() => {
  //         // Perform your desired action here
  //         if (searchedEmpName.trim() !== '') {
  //             fetchData();
  //         }
  //     }, 500); // Adjust the delay according to your needs

  //     return () => {
  //         clearTimeout(debounceTimer); // Clear the timeout on cleanup
  //     };
  // }, [searchedEmpName]);

  return (
    <div>
      <div className={` ' container-fluid background-main pb-5 ${theme === 'dark' ? 'black-color' : 'light-theme'}`}>
        <div className="container">
          <h2 className="search-note-h2 pt-5">All User list</h2>
          <div role="presentation">
            <Breadcrumbs aria-label="breadcrumb" className={` ' breadcrumbs pb-5 ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}>
              <Link underline="hover" color="inherit" to="/home" href="/home" className={` ' pb-5 ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}>
                Home
              </Link>

              <Typography color="text.primary">
                {" "}
                <b className={` ' pb-5 theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}>User listing</b>
              </Typography>
            </Breadcrumbs>
          </div>
          <div className="row position-relative">
            <div className={`search-bar d-flex theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>
              <FontAwesomeIcon icon={faMagnifyingGlass} className="p-3" />
              <input
                type="text"
                placeholder="Search..."
                value={selectedItems?.search}
                onChange={(e) => setSelectedItems({ ...selectedItems, search: e.target.value })}
                className={`${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}
              />
            </div>

            <div className={`search-bar search-bar-right d-flex  float-end theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>

              <Fillter onAddRemove={(val) => handleFilterAddRemove(val)} handleAgeSelect={handleAgeSelect} setIsUserListFilterDivPresent={setIsUserListFilterDivPresent} />
            </div>
            {/* {isUserListFilterDivPresent
              ? '' : <button className={`clear-fillter theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}
                onClick={handleClearFilter}>Clear filter</button>} */}
          </div>
          {hashtagsArray && hashtagsArray.length ?
            <h4 className="applied-hashtags mt-4">
              Applied hashtags
              <span style={{ float: "right", fontSize: "15px", color: "#B40FE7", fontWeight: "400", textDecoration: "underline", cursor: 'pointer' }} onClick={() => {
                setHashtagsArray([]);
                localStorage.removeItem("saved-note-hashtags");
              }}>
                Clear all
              </span>
            </h4> : ''}
          <div className="flex flex-wrap gap-2 mt-4">
            {hashtagsArray.map((tag, index) => (
              <div
                key={index}
                className="flex items-center gap-1 mt-2 rounded-full text-sm"
                style={{
                  background: 'linear-gradient(to right, #f10b80, #830d9a)',
                  color: 'white',
                  borderRadius: '20px',
                  padding: '10px 20px',
                  position: "relative",
                }}
              >
                #{tag}
                <button
                  onClick={() => removeHashtag(index)}
                  className="hover:bg-pink-600 rounded-full hastag-remove"
                  style={{
                    background: 'transparent',
                    border: 'none',
                    cursor: 'pointer',
                  }}
                >
                  <FontAwesomeIcon icon={faX} className="pt-1" />
                </button>
              </div>
            ))}
          </div>
          <HomeCard refer={senderId ? true : false} users={fetchedUsers} nid={id} handleSelect={handleSelect} />
          {fetchedUsers && fetchedUsers.length > 0
            ? <div className="mt-4 pb-5 m-auto">
              <button className={` ' save-draft-btn mt-4 theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`} onClick={() => navigate(-1)}>Close</button>
              {inviteIds && inviteIds.length > 0
                ?
                <button className='search-btn mt-4'
                  onClick={senderId ? handleRefer : sendInviteHandler}
                >
                  {senderId ? `Send Reference` : `Send Invitation`}</button>
                :
                <button className='search-btn mt-4'
                  onClick={() => notifyError("Select atleast one user")}
                > {senderId ? `Send Reference` : `Send Invitation`}</button>
              }
            </div> : ''}
        </div>
      </div>
    </div>
  );
}

export default Index;
