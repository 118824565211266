import React, { useState } from "react";
import {
  TextField,
  Popover,
  IconButton,
  Typography,
  Grid,
  Button,
  MenuItem,
  Select,
} from "@mui/material";
import { CalendarToday, ChevronLeft, ChevronRight } from "@mui/icons-material";
import {
  format,
  addMonths,
  subMonths,
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  isSameMonth,
  isSameDay,
  differenceInYears,
  setYear,
  getYear,
} from "date-fns";
import { styled } from "@mui/material/styles";
import calender from "../../../Images/calender.svg";
import dobsvg from "../../../Images/signupsvgs/dob.svg";

const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    borderRadius: "100px",
    "& fieldset": {
      borderColor: "#e0e0e0",
    },
    "&:hover fieldset": {
      borderColor: "#e0e0e0",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#e0e0e0",
    },
  },
  "& .MuiOutlinedInput-input": {
    padding: "15px 16px 15px 10px",
    "&::placeholder": {
      color: "#666",
      opacity: 1,
    },
  },
});

const CustomIconButton = styled(IconButton)({
  color: "#f10b80",
  padding: 0,
});

const CalendarButton = styled(Button)({
  minWidth: 35,
  width: 35,
  height: 35,
  padding: 0,
  borderRadius: 8,
  "&.Mui-selected": {
    backgroundColor: "#f10b80",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#f10b80",
    },
  },
});

const MonthNavigationButton = styled(IconButton)({
  color: "#f10b80",
  padding: 4,
  "&:hover": {
    backgroundColor: "rgba(76, 175, 80, 0.04)",
  },
});

const CustomDatePicker = ({
  label = "Date of birth",
  value,
  onChange,
  max,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(() => {
    const maxMonth = max ? new Date(max) : new Date();
    return maxMonth;
  });
  const [selectedDate, setSelectedDate] = useState(value);
  const [currentYear, setCurrentYear] = useState(() => {
    const maxDate = max ? new Date(max) : new Date();
    return getYear(maxDate);
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const days = React.useMemo(() => {
    const start = startOfMonth(currentMonth);
    const end = endOfMonth(currentMonth);
    return eachDayOfInterval({ start, end });
  }, [currentMonth]);

  const handleDateSelect = (date) => {
    setSelectedDate(date);
    onChange?.(date);
    handleClose();
  };

  const handlePreviousMonth = () => {
    setCurrentMonth((prev) => subMonths(prev, 1));
  };

  const handleNextMonth = () => {

    setCurrentMonth((prev) => addMonths(prev, 1));
  };

  const handleYearChange = (event) => {
    const newYear = event.target.value;
    setCurrentYear(newYear);
    setCurrentMonth((prev) => setYear(prev, newYear));
  };

  const calculateAge = (dob) => {
    if (!dob) return "";
    return differenceInYears(new Date(), dob);
  };

  const renderYearOptions = () => {

    const startYear = new Date().getFullYear() - 72 // 1900; // Earliest year in the dropdown
    const endYear = max ? new Date(max).getFullYear() : new Date().getFullYear(); // Current year
    const years = [];
    for (let year = endYear; year >= startYear; year--) {
      years.push(
        <MenuItem key={year} value={year}>
          {year}
        </MenuItem>
      );
    }
    return years;
  };

//   console.log(`current month`, new Date(currentMonth).getMonth())

  return (
    <div style={{ maxWidth: "100%", position: "relative" }}>
      <CustomTextField
        placeholder={label}
        value={selectedDate ? format(selectedDate, "PP") : ""}
        onClick={handleClick}
        fullWidth
        InputProps={{
          readOnly: true,
          startAdornment: (
            <CustomIconButton>
              <img src={dobsvg} alt="" />
            </CustomIconButton>
          ),
        }}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        PaperProps={{
          sx: {
            borderRadius: "12px",
            boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
            mt: 1,
          },
        }}
      >
        <div style={{ padding: 20, width: 320 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 20,
              outline: "none",
            }}
          >
            <Typography
              className="mb-0"
              style={{
                color: "#f10b80",
                fontSize: "18px",
                fontWeight: 700,
                outline: "none",
              }}
            >
              {format(currentMonth, "MMMM")}
            </Typography>
            <Select
              value={currentYear}
              onChange={handleYearChange}
              style={{
                marginLeft: "16px",
                color: "#f10b80",
                outline: "none",
                border: "none",
              }}
            >
              {renderYearOptions()}
            </Select>
            <div>
              {new Date(currentMonth).getMonth() == 0
                ? ''
                :
                <MonthNavigationButton onClick={handlePreviousMonth} size="small">
                  <ChevronLeft />
                </MonthNavigationButton>
              }
              {/* {max && new Date(max).getFullYear() == currentYear && new Date(max).getMonth() + 1 == currentMonth? '' */}
              {/* :  */}
              {new Date(currentMonth).getMonth() == 11
                ? ''
                :
                <MonthNavigationButton onClick={handleNextMonth} size="small">
                  <ChevronRight />
                </MonthNavigationButton>
              }
              {/* } */}
            </div>
          </div>
          <Grid container spacing={0.5} columns={7}>
            {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((day) => (
              <Grid item key={day} xs={1}>
                <Typography
                  align="center"
                  style={{
                    fontSize: "13px",
                    color: "#3C4045",
                    marginBottom: "8px",
                    fontWeight: 700,
                  }}
                >
                  {day}
                </Typography>
              </Grid>
            ))}
            {Array.from({ length: days[0].getDay() }).map((_, index) => (
              <Grid item key={`empty-${index}`} xs={1}>
                <CalendarButton disabled style={{ visibility: "hidden" }} />
              </Grid>
            ))}
            {days.map((day) => (
              <Grid item key={day.toISOString()} xs={1}>
                <CalendarButton
                  onClick={() => handleDateSelect(day)}
                  className={isSameDay(day, selectedDate) ? "Mui-selected" : ""}
                  disabled={
                    !isSameMonth(day, currentMonth) || (max && day > max)
                  }
                >
                  <Typography
                    style={{
                      fontSize: "14px",
                      color:
                        max && day > max
                          ? "#aaa"
                          : isSameDay(day, selectedDate)
                            ? "#fff"
                            : "#000",
                    }}
                  >
                    {format(day, "d")}
                  </Typography>
                </CalendarButton>
              </Grid>
            ))}
          </Grid>
        </div>
      </Popover>
      {selectedDate && (
        <Typography
          style={{
            position: "absolute",
            bottom: "-25px",
            right: 0,
            color: "#6E6E6E",
            fontSize: "14px",
          }}
        >
          Age: {calculateAge(selectedDate)} years
        </Typography>
      )}
    </div>
  );
};

export default CustomDatePicker;
