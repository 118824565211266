'use client';

// import { User } from 'lucide-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import CompleteProfileUser from '../../Images/CompleteProfileUser.svg'

function CompletionPercentage({ percentage = 0, propImg = null }) {
  const navigate = useNavigate();
  const radius = 28;
  const strokeWidth = 1.6;
  const normalizedRadius = radius - strokeWidth / 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - (percentage / 100) * circumference;

  return (
    <div className="flex items-start mt-2 mr-2" onClick={() => navigate(`/profile`)} style={{cursor: 'pointer'}}>
      <div className="relative">
        <svg height={radius * 2} width={radius * 2} className="rotate-[-90deg]">
          <defs>
            <linearGradient id="progressGradient" x1="0%" y1="0%" x2="100%" y2="100%">
              <stop offset="0%" stopColor="#E91E63" />
              <stop offset="100%" stopColor="#9C27B0" />
            </linearGradient>
          </defs>
          <circle
            // stroke="#e6e6e6"
            fill="transparent"
            strokeWidth={strokeWidth}
            r={normalizedRadius}
            cx={radius}
            cy={radius}
          />
          <circle
            stroke="url(#progressGradient)"
            fill="transparent"
            strokeWidth={strokeWidth}
            // strokeLinecap="round"
            strokeDasharray={`${circumference} ${circumference}`}
            style={{ strokeDashoffset }}
            r={normalizedRadius}
            cx={radius}
            cy={radius}
          />
        </svg>
        <div className="absolute inset-0 flex items-center justify-center">
          <img src={propImg || CompleteProfileUser} style={{ width: 50, height: 50, borderRadius: '50%' }} />
        </div>
      </div>
      <span className="text-[10px] text-[#970c95] font-medium no-underline">{percentage}%</span> 
    </div>
  );
}

export default CompletionPercentage;
