import React, { useEffect, useState } from "react";
import Image from "../../../Images/10782771_19197307.jpg";
import Footer from "../../Footer";
import image from "../../../Images/Requirements-for-Youtube-Channel-Monetization-1024x512-1-150x150_c.jpg";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { getAllBlogs } from "../../../services";
import { notifyError } from "../../../toastNotifications";

function Blog() {
  const [blogsData, setBlogsData] = useState([]);

  const fetchAllBlogs = async () => {
    setBlogsData([]);
    try {
      const response = await getAllBlogs();
      console.log("getAllBlogs response is here -->", response);
      if (response?.statusCode === 200) {
        let resBlogs = response?.data?.blogs || [];
        setBlogsData(
          resBlogs.map((blog) => ({
            ...blog,
            readMore: false, // Initialize readMore state for each blog
          }))
        );
      }
    } catch (error) {
      console.error("Error in addBlogs :", error);
      notifyError(error?.response?.data?.message || `Something went wrong`, {
        autoClose: 1000,
      });
    }
  };

  const toggleReadMore = (index) => {
    setBlogsData((prev) =>
      prev.map((blog, i) =>
        i === index ? { ...blog, readMore: !blog.readMore } : blog
      )
    );
  };

  useEffect(() => {
    fetchAllBlogs();
  }, []);

  return (
    <div>
      <Helmet>
        <title>
          Blog | AskHuman AI: Insights on AI, Customer Support & Innovation{" "}
        </title>
        <meta
          name="description"
          content="Stay updated with the latest trends in AI and customer support. Explore insights, tips, and innovations from AskHuman AI on our blog."
        />
      </Helmet>
      <div className="container-fluid mein-header-blog-video pt-5 pb-5">
        <h1 className="mt-5">Latest Topics</h1>
      </div>
      {blogsData && blogsData.length > 0
        ? blogsData.map((blog, index) => {
            let _id = blog?._id || null;
            let title = blog?.title || "Not Found";
            let blog_id = blog?.blog_id || null;
            let slug = blog?.slug || null;
            let content = blog?.content || "<p>Not Found</p>";
            let createdAt = blog?.createdAt || null;
            let blogImg = blog?.imageUrls?.[0] || null;

            return (
              <div className="container" key={_id}>
                <Link
                  to={`/blog-details/${blog_id}/${slug}`}
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <div className="row mt-5 mb-5 border-1 p-3 blog-border">
                  
                    <div className="col-lg-5">
                    <p>
                      <b>
                      {title}
                      </b>
                    </p>
                      <img
                        src={blogImg || image}
                        alt=""
                        className="rounded-3 mt-2"
                        height={200}
                        style={{ width: "100%", objectFit: "cover" }}
                      />
                    </div>
                    <div className="col-lg-7 pt-4">
  <div
    dangerouslySetInnerHTML={{
      __html: `${content
        .replace(/<\/?[^>]+(>|$)/g, "") // Remove HTML tags
        .split(/\s+/)                  // Split by spaces
        .slice(0, 70)                 // Limit to 100 words
        .join(" ")}...`,               // Join words and add ellipsis
    }}
  />
  <div className="mt-4">
  <span className="span-blog mt-4 ">
                        {/* {createdAt
                          ? new Date(createdAt).toLocaleDateString("en-US", {
                              year: "numeric",
                              month: "2-digit",
                              day: "2-digit",
                            })
                          : "Not Found"} */}
                          View More
                      </span>
  </div>
</div>

                    <div className="col-lg-12 pb-3">
                      <br />
                      <center>
                     
                      </center>
                    </div>
                  </div>
                </Link>
              </div>
            );
          })
        : ""}
      <Footer />
    </div>
  );
}

export default Blog;
