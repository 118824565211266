import axiosInstance from '../axiosInstance';

export const getAllBlogs = async (data) => {
    try {
        const response = await axiosInstance.get(`/user/listBlogs`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getABlog = async (data) => {
    const { blog_id, slug } = data;
    try {
        const response = await axiosInstance.get(`/user/getBlogDetails/${blog_id}/${slug}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};


