import axiosInstance from '../axiosInstance';

export const searchHastags = async (data) => {
    try {
        const response = await axiosInstance.get(`/user/hashtags/getHashtags/${data}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};


export const requestVerificationHashtag = async (dataVal) => {
    try {
        const response = await axiosInstance.post(`/user/hashtags/requestVerification`, dataVal);
        return response.data;
    } catch (error) {
        throw error;
    }
};