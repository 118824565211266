import React from "react";
import trustedus from "../../../Images/trusted-us.png";
import trustedus1 from "../../../Images/trustedus4.png";
import trustedus4 from "../../../Images/qu-nh-le-m-nh-dp3CS405UZM-unsplash.jpg";
import trustedus5 from "../../../Images/5060585_2668383 1.png";
import { Helmet } from "react-helmet-async";
import Footer from "../../Footer";

import trustedusw from "../../../Images/womanimage (4).png";
import trustedusw1 from "../../../Images/womanimage (3).png";
import trustedusw2 from "../../../Images/womanimage (2).png";
import trustedusw3 from "../../../Images/womanimage (1).png";


function AboutUs() {
  return (
    <>
      <Helmet>
        <title>
          About Us | AskHuman AI: Innovating Customer Support with AI{" "}
        </title>
        <meta
          name="description"
          content="Discover how AskHuman AI enhances customer support with advanced AI-driven solutions for businesses. Learn more about our mission and services."
        />
      </Helmet>
      <div>
        <div className="about-header">
          <h1>About Us</h1>
          <p>
            Ask questions from experts or earn rewards by answering others'
            queries on Ask Human dedicated to knowledge sharing and community
            engagement
          </p>
        </div>
        <div id="about">
          <div className={` container-fluid trusted-us `}>
            <div className="container pt-5 pb-5">
              <div className="row">
                <div className="col-md-6">
                  <img src={trustedus} alt="" className="trusted-us-image" />
                </div>

                <div className="col-md-6 d-flex justify-content-center align-items-center text-center">
                  <div className="">
                    <h5 className={`why-choose-h5 text-start  pb-2 `}>
                      Our Mission
                    </h5>
                    <p>
                      At Ask Human, we are dedicated to connecting people with
                      real human experts who can provide accurate, personalized
                      answers to their questions. Our mission is to make expert
                      advice accessible to everyone, fostering a community where
                      knowledge is shared, and curiosity is encouraged. We
                      believe in the power of human interaction and the value of
                      expert insights to enhance personal and professional
                      growth.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="container pt-3 pb-5">
              <div className="row">
                <div className="col-md-6 d-flex justify-content-center align-items-center text-center">
                  <div className="">
                    <h5 className={`why-choose-h5  pb-2 text-start`}>
                      Our Commitment
                    </h5>
                    <p>
                      At Ask Human, we are committed to continuous improvement
                      and innovation. We regularly update our platform to
                      enhance user experience and expand our network of experts.
                      Feedback from our users is invaluable, helping us to
                      refine our services and better meet your needs. Join us
                      today and become part of a community where knowledge is
                      shared, and everyone has access to expert advice.
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <img src={trustedus1} alt="" className="trusted-us-image" />
                </div>
              </div>
            </div>
            <div className="container pt-2 pb-5">
              <div className="row">
                <div className="col-md-6">
                  <img src={trustedus5} alt="" className="trusted-us-image" />
                </div>

                <div className="col-md-6 d-flex justify-content-center align-items-center text-center">
                  <div className="">
                    <h5 className={`why-choose-h5  pb-2 text-start `}>
                      How We Work
                    </h5>
                    <p>
                      We have designed our platform to be user-friendly and
                      efficient. Users can easily create an account, post their
                      questions, and invite experts to chat. Our transparent
                      payment system ensures that both question askers and
                      experts are fairly compensated for their time and
                      knowledge. We are committed to maintaining a high level of
                      service, offering refunds and dispute resolution to ensure
                      user satisfaction.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="our-team">
          <div className="container">
            <h1>Our Team</h1>
            <p>
            At AskHuman, we’re driven by a passion for connecting people with real-world wisdom. Meet the incredible team making this vision a reality:

            </p>
            <div className="row mt-5">
              <div className="col-md-6  mt-2">
                <div className="our-card">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="our-card-inner-image-upper">
                        <img
                          src={trustedusw}
                          alt=""
                          className="our-card-inner-image"
                        />
                      </div>
                    </div>
                    <div className="col-md-8">
                      <h5 className="pt-4">Alex Smith </h5>
                      <p>
                      We started AskHuman with a simple goal: empower people by connecting them with others who’ve already achieved their dreams. This platform is about real humans helping real humans.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mt-2">
                <div className="our-card">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="our-card-inner-image-upper">
                        <img
                          src={trustedusw3}
                          alt=""
                          className="our-card-inner-image"
                        />
                      </div>
                    </div>
                    <div className="col-md-8">
                      <h5 className="pt-4">Sara Johnson </h5>
                      <p>
                      Our team built AskHuman to be intuitive and seamless, matching users with the right people through hashtags. Real expertise should always be one search away.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mt-2">
                <div className="our-card">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="our-card-inner-image-upper">
                        <img
                          src={trustedusw2}
                          alt=""
                          className="our-card-inner-image"
                        />
                      </div>
                    </div>
                    <div className="col-md-8">
                      <h5 className="pt-4">Michael Lee</h5>
                      <p>
                      Building connections is at the heart of AskHuman. My role is to foster a space where meaningful conversations and relationships flourish.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mt-2">
                <div className="our-card">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="our-card-inner-image-upper">
                        <img
                          src={trustedusw1}
                          alt=""
                          className="our-card-inner-image"
                        />
                      </div>
                    </div>
                    <div className="col-md-8">
                      <h5 className="pt-4">Jessica Brown</h5>
                      <p>
                      We share the inspiring stories of those who give advice and those who receive it. Everyone has a journey, and at AskHuman, these stories matter.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default AboutUs;
