import { toast } from 'react-toastify';

export const notifySuccess = (message) => {
  toast.success(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    className: "custom-toast", // Custom class name
    bodyClassName: "custom-toast-body",
    progressStyle: {
      background: "#fff", // Progress bar color
    },
  });
};

export const notifyError = (message) => {
  toast.error(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    className: "custom-toast",
    bodyClassName: "custom-toast-body",
    progressStyle: {
      background: "#fff",
    },
  });
};
