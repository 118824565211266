import React from 'react'
import whychooseicon1 from '../../Images/why-choose-icon-1.png';
import whychooseicon2 from '../../Images/why-choose-icon-2.png';
import whychooseicon3 from '../../Images/why-choose-icon-3.png';
import { useState, useEffect } from 'react';

const WhyChoose = () => {
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');

  useEffect(() => {
    const storedTheme = localStorage.getItem('theme');
    if (storedTheme) {
      setTheme(storedTheme);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('theme', theme);
  }, [theme]);

  return (
    <div>
      <div className="container why-choose">
        <h5 className={`why-choose-h5  pt-2 theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}>Why Choose Ask Human!</h5>

        <div className="row pb-5 pt-2">

          <div className="col-lg-4 col-md-6 mt-4">
            <div className="why-choose-card p-4 card-hover">
              <img src={whychooseicon1} alt="" />
              <h5 className={`mt-2 theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}>Real-World Wisdom</h5>
              <p className={` theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}>Unlike automated search engines, our platform connects you with people who’ve been in your shoes and achieved success.</p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 mt-4 mt-4">
            <div className="why-choose-card p-4 card-hover">
              <img src={whychooseicon2} alt="" />
              <h5 className={`mt-2 theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}>Personalized Guidance</h5>
              <p className={` theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}>With hashtags, you’ll easily find experts aligned with your specific aspirations, saving time and ensuring relevance.</p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 mt-4 mt-4">
            <div className="why-choose-card p-4 card-hover">
              <img src={whychooseicon3} alt="" />
              <h5 className={`mt-2 theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}>Community-Driven Growth</h5>
              <p className={` theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}>Become part of a supportive ecosystem where knowledge flows freely between those who’ve succeeded and those on the rise.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WhyChoose