import React, { useState, useEffect } from 'react'
import Image from '../../../Images/10782771_19197307.jpg';
import Footer from "../../Footer";
import { getABlog } from '../../../services';
import { notifyError } from '../../../toastNotifications';
import { useParams } from 'react-router-dom';
import Loaders from '../../Loaders/index'

function Blog() {
  const [content, setContent] = useState('');
  const [title, setTitle] = useState('');
  const [image, setImage] = useState(null);
  const [hovered, setHovered] = useState(false);
  const [loading, setLoading] = useState(true);

  // const { id } = useParams();
  const { blog_id, slug } = useParams();

  const fetchBlog = async () => {
    let data = { blog_id, slug}
    try {
      const response = await getABlog(data);
      console.log("getABlog response is here -->", response);
      if (response?.statusCode === 200) {
        let resBlogs = response?.data?.blog || null;
        let resBlogTitle = response?.data?.blog?.title || '';
        let resBlogContent = response?.data?.blog?.content || '';
        let resBlogImage = response?.data?.blog?.imageUrls?.[0] || '';

        setTitle(resBlogTitle);
        setContent(resBlogContent);
        setImage(resBlogImage)
      }
    } catch (error) {
      // console.error("Error in addBlogs :", error);
      notifyError(error?.response?.data?.message || `Something went wrong`, { autoClose: 1000 })
    }
    finally {
      setLoading(false); // Set loading to false when data fetching is complete
    }
  }

  useEffect(() => {
    fetchBlog()
  }, []);

  if (loading) {
    return <Loaders />; // Render the loader while loading is true
  }



  return (
    <div>
      <div className="container mein-header-blog pt-5 pb-5">
        <h1 className='text-center'>{title || `Blog title`}</h1>
        <div className="row mt-4">
          {/* <div className="col-lg-6 mein-header-blog-left pt-5" >
            <p>{title}</p>
          </div> */}
          <div className="col-lg-12">
            <img
              src={image || Image}
              alt=""
              className="m-auto rounded-5 mb-4"
              style={{
                maxHeight: `300px`,
                objectFit: `cover`,
                width: `100%`,
                transform: hovered ? 'scale(1.1)' : 'scale(1)',
                transition: 'transform 0.3s ease',
              }}
              onMouseEnter={() => setHovered(true)}
              onMouseLeave={() => setHovered(false)}
            />
          </div>
        </div>
        <hr />
        <div dangerouslySetInnerHTML={{ __html: content || `<p>Blog content</p>` }} />

        {/* 

        <h5 className='pt-4'>The Limitations of Automated Responses</h5>
        <p>Search engines and AI-driven assistants have their place in our
          digital lives, offering quick access to a vast array of information.
          However, they often fall short in providing the detailed, context-
          specific answers that many of us seek. Automated responses can
          be impersonal, sometimes even inaccurate, and often fail to
          address the unique aspects of individual queries.</p>
        <p>For instance, if you’re dealing with a complex financial issue, a
          legal question, or a health concern, a generic answer from a
          search engine might not suffice. These areas require a nuanced
          understanding that only a trained expert can offer. This is where
          Ask Human steps in, connecting you with real professionals who
          can provide the insight and guidance you need.</p>


        <h5 className='mt-4'>
          The Value of Human Expertise

        </h5>
        <p>At Ask Human, we have assembled a diverse team of experts
          from various fields, including finance, law, health, education,
          technology, and more. Our experts are not just knowledgeable;
          they are passionate about sharing their insights and helping
          others. When you ask a question on our platform, you’re tapping
          into a wealth of experience and specialized knowledge that can
          make a significant difference in your decision-making process.</p>
        <p>One of the key advantages of seeking answers from real people is
          the ability to receive personalized responses. Our experts
          consider the specific details and context of your question,
          ensuring that their advice is tailored to your unique situation. This
          level of personalization can lead to more accurate, relevant, and
          practical solutions.</p>

        <h5 className='pt-4'>How It Works</h5>
        <p>Using Ask Human is simple and intuitive. For those looking to ask
          questions, it begins with creating an account on our platform.
          Once registered, you can post your question and browse through
          our list of experts to find the right match for your query. After
          sending an invitation to chat, you can receive detailed answers
          and continue the conversation for further clarification if needed.</p>
        <p>For those providing answers, the process is equally
          straightforward. Experts create a profile highlighting their areas of
          expertise and experience. They can then browse incoming
          invitations and accept the ones that align with their skills. This
          system not only ensures that users receive high-quality answers
          but also allows experts to engage in meaningful and rewarding
          interactions.</p>

        <h5 className='pt-4'>Building a Community of Knowledge</h5>
        <p>At Ask Human, we are more than just a Q&A platform; we are
          building a community where knowledge is shared, and curiosity is
          celebrated. Our mission is to foster a space where people can
          seek reliable, expert advice and experts can share their insights
          and grow their professional network.</p>
        <p>We also believe in continuous improvement and are committed to
          enhancing our platform based on user feedback. Your satisfaction
          is our priority, and we offer a dispute resolution process to ensure
          that you are always happy with the answers you receive.</p>

        <h5 className='pt-4'>Conclusion</h5>
        <p>In today’s fast-paced world, the value of real human expertise
          cannot be overstated. Whether you have a burning question that
          needs an immediate answer or a complex issue that requires in-
          depth analysis, Ask Human is here to connect you with the right
          experts. Join us today and experience the difference that real
          answers from real people can make.</p> */}




      </div>
      <Footer />
    </div>
  )
}

export default Blog