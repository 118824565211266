import React, { useState, useEffect } from "react";
import { Formik, Field, ErrorMessage, Form as FormikForm } from "formik";
import * as Yup from "yup";
import logo from "../../../Images/logo-mini.png";
import Form from "react-bootstrap/Form";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import google from "../../../Images/google.png";
import apple from "../../../Images/apple.png";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhotoFilm } from "@fortawesome/free-solid-svg-icons";
import InputGroup from "react-bootstrap/InputGroup";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import xmark from "../../../Images/x-mark.png";
import LightBulb from "../../../Images/3d-render-hand-with-light-bulb-creative-idea.png";
import { photoUpload, profileComplete } from "../../../services";
import Range from "./range";
import { notifyError, notifySuccess } from "../../../toastNotifications";
import NavbarSignFlow from "../../Login/NavbarSignFlow";
import CustomDatePickerWithoutMonth from "./CustomDatePickerWithoutMonth";
import App from "./HashtagComponent";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#E30B83" : "#E30B83",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

const zodiacSigns = [
  "Jawan",
  "Munawwar Rangila",
  "Salsabilla",
  "Now Entertainment",
];

// const zodiacSigns = [
//   'Aries',
//   'Taurus',
//   'Gemini',
//   'Cancer',
//   'Leo',
//   'Virgo',
//   'Libra',
//   'Scorpio',
//   'Sagittarius',
//   'Capricorn',
//   'Aquarius',
//   'Pisces'
// ];

// const validationSchema = Yup.object({
// name: Yup.string().required('Name is required'),
// about: Yup.string().required('About is required'),
// gender: Yup.string().required('Gender is required'),
// ethnicity: Yup.string().required('Ethnicity is required'),
// zodiac: Yup.string().required('Zodiac is required'),
// age: Yup.number().required('Age is required').positive().integer(),
// smoke: Yup.string().required('Smoke status is required'),
// drink: Yup.string().required('Drink status is required'),
// workout: Yup.string().required('Workout status is required'),
// bodytype: Yup.string().required('Bodytype is required'),
// hoursPrice: Yup.number().required('Hourly price is required').positive(),
// });

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .test(
      "notEmptyAfterTrim",
      "Name is required",
      (value) => value.trim() !== ""
    )
    .required("Name is required"),
  about: Yup.string(),
  // .trim()
  //   .test('notEmptyAfterTrim', 'About is required', value => value.trim() !== '')
  //   .required("About is required"),
  gender: Yup.string()
    .trim()
    .test(
      "notEmptyAfterTrim",
      "Gender is required",
      (value) => value.trim() !== ""
    )
    .required("Gender is required"),
  ethnicity: Yup.string()
    .trim()
    .test(
      "notEmptyAfterTrim",
      "Ethnicity is required",
      (value) => value.trim() !== ""
    )
    .required("Ethnicity is required"),
  zodiac: Yup.string()
    .trim()
    .test(
      "notEmptyAfterTrim",
      "Zodiac is required",
      (value) => value.trim() !== ""
    )
    .required("Zodiac is required"),
  hoursPrice: Yup.number()
    .positive("positive number allowed only")
    .required("Hourly price is required")
    .max(
      10000000,
      "You can set the hourly price up to 10,000,000 dollars only."
    ),
  age: Yup.number()
    .positive("positive number allowed only")
    .min(18, "Minimum age 18 required")
    .max(72, "Minimum age 72 allowed")
    .required("Age is required"),
  smoke: Yup.string()
    .trim()
    .test(
      "notEmptyAfterTrim",
      "Smoke status is required",
      (value) => value.trim() !== ""
    )
    .required("Smoke status is required"),
  drink: Yup.string()
    .trim()
    .test(
      "notEmptyAfterTrim",
      "Drink status is required",
      (value) => value.trim() !== ""
    )
    .required("Drink status is required"),
  workout: Yup.string()
    .trim()
    .test(
      "notEmptyAfterTrim",
      "Workout status is required",
      (value) => value.trim() !== ""
    )
    .required("Workout status is required"),
  bodytype: Yup.string()
    .trim()
    .test(
      "notEmptyAfterTrim",
      "Bodytype status is required",
      (value) => value.trim() !== ""
    )
    .required("Bodytype status is required"),
});

function Index() {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const [imagePreview, setImagePreview] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [imageDataUrl, setImageDataUrl] = useState(null);
  const [modelOpenStatus, setModelOpenStatus] = useState(false);
  const [imageError, setImageError] = useState(null);
  const [selectedHashTags, setSelectedHashTags] = useState([]);

  const handleOpen = () => setModelOpenStatus(true);
  const handleClose = () => setModelOpenStatus(false);

  const handleButtonClick = () => {
    handleClose();
    window.location.href = "/home";
  };

  // const photoUpload = async (formData) => {
  //   try {
  //     // Upload the file using the photoUpload function
  //     const imageUpload = await photoUpload(formData);
  //     if (imageUpload.statusCode === 200) {
  //       console.log(imageUpload.data.imageUrl, "imageUpload.data.imageUrl")
  //       setImageUrl(imageUpload.data.imageUrl);
  //       setImagePreview(imageUpload.data.imageUrl)
  //       console.log(imageUrl, "imageUrl")
  //     }
  //   } catch (error) {
  //     console.error("Error uploading image:", error);
  //   }
  // };

  const handleSubmit = async (values, { setSubmitting }) => {
    if (!imageFile) {
      setImageError(true);
      notifyError(`Profile image required`);
      return;
    } else {
      setImageError(false);
    }

    const {
      name,
      about,
      gender,
      ethnicity,
      age,
      zodiac,
      smoke,
      drink,
      workout,
      bodytype,
      hoursPrice,
    } = values;
    if (!imageFile) {
      setImageError(true);
    } else {
      setImageError(false);

      try {
        console.log("imageFile is here --> ", imageFile);
        const file = imageFile;
        console.log(file, "filefilefilefile");

        if (!file) {
          console.error("No file selected");
          notifyError(`Image required`);
          return;
        }

        // Create a new FileReader instance

        const reader = new FileReader();

        // reader.onloadend = async () => {
        const dataURL = imageDataUrl;
        console.log("handleUpload dataURL is here -->", dataURL);

        // Convert data URL to Blob
        const byteString = atob(dataURL.split(",")[1]);
        const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([ab], { type: mimeString });

        // Create form data object to send binary data
        console.log("imageFile.name is here --> ", file.name);

        const newForm = new FormData();
        newForm.append("profileImage", blob, file.name);
        newForm.append("name", name);
        newForm.append("about", "");
        newForm.append("gender", gender);
        newForm.append("ethnicity", ethnicity);
        newForm.append("zodiac", zodiac);
        newForm.append("age", age);
        newForm.append("smoke", smoke);
        newForm.append("drink", drink);
        newForm.append("workout", workout);
        newForm.append("bodytype", bodytype);
        newForm.append("hoursPrice", hoursPrice);
        if (selectedHashTags && selectedHashTags.length > 0) {
          newForm.append("hashtags", JSON.stringify(selectedHashTags));
        }
        try {
          const response = await profileComplete(newForm);
          console.log("profileComplete response ==>>", response);
          if (response.statusCode === 200) {
            handleOpen();
            notifySuccess(response.message);
          }
        } catch (error) {
          console.error("Error uploading photo:", error);
          notifyError(error?.response?.data?.message || `Something went wrong`);
        }
        // };

        // Read the file as a binary string
        reader.readAsDataURL(file); // Use readAsDataURL to set image preview correctly
      } catch (error) {
        console.error("Error uploading file:", error);
      } finally {
        setSubmitting(false);
      }
    }
  };

  const handleFileChange = async (event) => {
    console.log("working================================here");
    try {
      const file = event.target.files[0];
      setImageFile(file);
      setImageError(false);
      console.log(file, "filefilefilefile");
      if (!file) {
        console.error("No file selected");
        return;
      }
      const reader = new FileReader();
      reader.onloadend = async () => {
        const dataURL = reader.result;
        console.log(dataURL, "dataURL");
        setImageDataUrl(dataURL);
        // Convert data URL to Blob
        const byteString = atob(dataURL.split(",")[1]);
        const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([ab], { type: mimeString });
        // Create form data object to send binary data
        const formData = new FormData();
        formData.append("file", blob, file.name);
        try {
          // Upload the file using the photoUpload function
          const imageUpload = await photoUpload(formData);
          if (imageUpload.statusCode === 200) {
            console.log(imageUpload.data.imageUrl, "imageUpload.data.imageUrl");
            setImageUrl(imageUpload.data.imageUrl);
            setImagePreview(imageUpload.data.imageUrl);
            console.log(imageUrl, "imageUrl");
          }
        } catch (error) {
          console.error("Error uploading image:", error);
        }
      };
      // Read the file as a binary string
      reader.readAsDataURL(file); // Use readAsDataURL to set image preview correctly
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  // const handleUpload = async (values) => {
  //   try {
  //     const file = imageFile;
  //     if (!file) {
  //       console.error("No file selected");
  //       notifyError("Image required");
  //       return;
  //     }
  //     const reader = new FileReader();
  //     const dataURL = imageDataUrl;
  //     const byteString = atob(dataURL.split(",")[1]);
  //     const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
  //     const ab = new ArrayBuffer(byteString.length);
  //     const ia = new Uint8Array(ab);
  //     for (let i = 0; i < byteString.length; i++) {
  //       ia[i] = byteString.charCodeAt(i);
  //     }
  //     const blob = new Blob([ab], { type: mimeString });
  //     const newForm = new FormData();
  //     newForm.append("profileImage", blob, file.name);
  //     Object.keys(values).forEach(key => {
  //       newForm.append(key, values[key]);
  //     });
  //     try {
  //       const response = await profileComplete(newForm);
  //       if (response.statusCode === 200) {
  //         handleOpen();
  //         notifySuccess(response.message);
  //       }
  //     } catch (error) {
  //       console.error("Error uploading photo:", error);
  //       notifyError(error?.response?.data?.message || "Something went wrong");
  //     }
  //   } catch (error) {
  //     console.error("Error uploading file:", error);
  //   }
  // };

  // const handleErrorCheck = async () => {
  //   if (!imageFile) {

  //     setImageError(true)
  //   } else {
  //     setImageError(false);
  //   }
  // };

  const [theme, setTheme] = useState(localStorage.getItem("theme") || "light");
  useEffect(() => {
    const storedTheme = localStorage.getItem("theme");
    if (storedTheme) {
      setTheme(storedTheme);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("theme", theme);
  }, [theme]);

  const handleSubmitHashtags = (values) => {
    console.log(`handleSubmitHashtags ===>>>`, values);
    setSelectedHashTags(values);
  };

  return (
    <div>
      <NavbarSignFlow />
      <div
        className={` ' container-fluid theme-switcher ${
          theme === "dark" ? "black-color" : "light-theme"
        }`}
      >
        <div className="row">
          <div className="col-md-7 login-left-section d-flex px-5">
            <div className="justify-content-center align-self-center">
              <h2>International ask Question with Ask Human!</h2>
              <p>Enter all the details for create the User Profile</p>
            </div>
          </div>
          <div
            className={` ' col-md-5 login-right-section m-auto theme-switcher ${
              theme === "dark" ? "dark-theme-only-white" : "light-theme"
            }`}
          >
            <div className="ask-to-sign px-3 pt-4">
              <h2 className="d-flex mt-4 pt-5 mt-5">
                Create User Profile <img src={logo} alt="" />
              </h2>

              <Formik
                initialValues={{
                  name: "",
                  about: "",
                  gender: "",
                  ethnicity: "",
                  zodiac: "",
                  age: "18",
                  smoke: "",
                  drink: "",
                  workout: "",
                  bodytype: "",
                  hoursPrice: "",
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ isSubmitting, values, setFieldValue, errors }) => (
                  <FormikForm className="mt-4 d-grid gap-2">
                    <Form.Label
                      htmlFor="profileImage"
                      className={` ' form-label mt-5  theme-switcher ${
                        theme === "dark"
                          ? "dark-theme-only-white"
                          : "light-theme"
                      }`}
                    >
                      Upload Profile
                    </Form.Label>
                    <br />
                    <input
                      type="file"
                      id="profileImage"
                      name="profileImage"
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                    />
                    <label htmlFor="profileImage">
                      {imagePreview ? (
                        <img
                          style={{ marginTop: "-25px" }}
                          src={imagePreview}
                          alt="Preview"
                          className="side-bar-profile object-fit-cover"
                        />
                      ) : (
                        <Button
                          component="span"
                          role={undefined}
                          variant="contained"
                          tabIndex={-1}
                          className="upload-file"
                          style={{ marginTop: "-25px" }}
                        >
                          <FontAwesomeIcon
                            icon={faPhotoFilm}
                            className="upload-file-icon mt-4 pt-1"
                          />
                        </Button>
                      )}
                    </label>
                    {
                      // Object.keys(errors).length > 0
                      //   ? imageError
                      //     ? <span className="text-danger">Profile image required</span> : ''
                      //   :
                      imageError ? (
                        <span className="text-danger">
                          Profile image required
                        </span>
                      ) : (
                        ""
                      )
                    }
                    {/* Conditionally render imagePreview */}
                    <Form.Group>
                      <Form.Label
                        className={` ' form-label  theme-switcher ${
                          theme === "dark"
                            ? "dark-theme-only-white"
                            : "light-theme"
                        }`}
                      >
                        User Name
                      </Form.Label>
                      <Field
                        type="text"
                        name="name"
                        placeholder="Enter Name"
                        className={`form-control form-input  theme-switcher ${
                          theme === "dark"
                            ? "dark-theme-only-color"
                            : "light-theme"
                        }`}
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="text-danger"
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label
                        className={`form-label mt-2  theme-switcher ${
                          theme === "dark"
                            ? "dark-theme-only-white"
                            : "light-theme"
                        }`}
                      >
                        Date of birth
                      </Form.Label>
                      <CustomDatePickerWithoutMonth />
                      {/* <Field
                        as="textarea"
                        name="about"
                        placeholder="Tell something about you"
                        className={`form-control form-input  theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}
                        style={{ height: (!values?.about.trim() || values?.about.trim() == "") ? '81px' : "unset" }}


                      />
                      <ErrorMessage
                        name="about"
                        component="div"
                        className="text-danger"
                      /> */}
                    </Form.Group>
                    <Form.Group>
                      <Form.Label
                        className={` ' form-label mt-2  theme-switcher ${
                          theme === "dark"
                            ? "dark-theme-only-white"
                            : "light-theme"
                        }`}
                      >
                        Select Gender
                      </Form.Label>
                      <Field
                        as="select"
                        name="gender"
                        className={` ' form-control form-input custom-select  theme-switcher ${
                          theme === "dark"
                            ? "dark-theme-only-color"
                            : "light-theme"
                        }`}
                      >
                        <option value="">Select Gender</option>
                        <option value="0">Male</option>
                        <option value="1">Female</option>
                        <option value="2">TS</option>
                      </Field>
                      <ErrorMessage
                        name="gender"
                        component="div"
                        className="text-danger"
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label
                        className={` ' form-label mt-2  theme-switcher ${
                          theme === "dark"
                            ? "dark-theme-only-white"
                            : "light-theme"
                        }`}
                      >
                        Select Ethnicity
                      </Form.Label>
                      <Field
                        as="select"
                        name="ethnicity"
                        className={` ' form-control form-input custom-select  theme-switcher ${
                          theme === "dark"
                            ? "dark-theme-only-color"
                            : "light-theme"
                        }`}
                      >
                        <option value="">Select Ethnicity</option>
                        <option value="White">White</option>
                        <option value="Black">Black</option>
                        <option value="Asian">Asian</option>
                        <option value="Indian">Indian</option>
                        <option value="Hispanic">Hispanic</option>
                        <option value="Middle Eastern">Middle Eastern</option>
                        <option value="Other">Other</option>
                      </Field>
                      <ErrorMessage
                        name="ethnicity"
                        component="div"
                        className="text-danger"
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label
                        className={` ' form-label mt-2  theme-switcher ${
                          theme === "dark"
                            ? "dark-theme-only-white"
                            : "light-theme"
                        }`}
                      >
                        Select Zodiac
                      </Form.Label>
                      <Field
                        as="select"
                        name="zodiac"
                        className={` ' form-control form-input custom-select  theme-switcher ${
                          theme === "dark"
                            ? "dark-theme-only-color"
                            : "light-theme"
                        }`}
                      >
                        <option value="">Select Zodiac</option>
                        {zodiacSigns.map((sign, index) => (
                          <option key={index} value={sign}>
                            {sign}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="zodiac"
                        component="div"
                        className="text-danger"
                      />
                    </Form.Group>
                    {/* <Form.Group>
                      <Form.Label className={` ' form-label mt-3  theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}>Select Age ({values.age})</Form.Label>
                      <Field
                        // type="number"
                        type="hidden"
                        name="age"
                        placeholder="Enter Age"
                        className={`form-control form-input  theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}
                      />
                      <Range value={values.age} onChange={(val) => setFieldValue('age', val)} />
                      <ErrorMessage
                        name="age"
                        component="div"
                        className="text-danger"
                        style={{ marginTop: '-25px' }}
                      />
                    </Form.Group> */}
                    <Form.Group>
                      <Form.Label
                        className={` ' form-label mt-3  theme-switcher ${
                          theme === "dark"
                            ? "dark-theme-only-white"
                            : "light-theme"
                        }`}
                      >
                        Do you Smoke ?
                      </Form.Label>
                      <Field
                        as="select"
                        name="smoke"
                        className={` ' form-control form-input custom-select  theme-switcher ${
                          theme === "dark"
                            ? "dark-theme-only-color"
                            : "light-theme"
                        }`}
                      >
                        <option value="">Select Option</option>
                        <option value="No">No</option>
                        <option value="Occasionally">Occasionally</option>
                        <option value="Regularly">Regularly</option>
                      </Field>
                      <ErrorMessage
                        name="smoke"
                        component="div"
                        className="text-danger"
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label
                        className={` ' form-label mt-3  theme-switcher ${
                          theme === "dark"
                            ? "dark-theme-only-white"
                            : "light-theme"
                        }`}
                      >
                        Do you Drink ?
                      </Form.Label>
                      <Field
                        as="select"
                        name="drink"
                        className={` ' form-control form-input custom-select  theme-switcher ${
                          theme === "dark"
                            ? "dark-theme-only-color"
                            : "light-theme"
                        }`}
                      >
                        <option value="">Select Option</option>
                        <option value="No">No</option>
                        <option value="Socially">Socially</option>
                        <option value="Regularly">Regularly</option>
                      </Field>
                      <ErrorMessage
                        name="drink"
                        component="div"
                        className="text-danger"
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label
                        className={` ' form-label mt-3  theme-switcher ${
                          theme === "dark"
                            ? "dark-theme-only-white"
                            : "light-theme"
                        }`}
                      >
                        Do you Workout ?
                      </Form.Label>
                      <Field
                        as="select"
                        name="workout"
                        className={` ' form-control form-input custom-select  theme-switcher ${
                          theme === "dark"
                            ? "dark-theme-only-color"
                            : "light-theme"
                        }`}
                      >
                        <option value="">Select Option</option>
                        <option value="Yoga">Yoga</option>
                        <option value="Long Walk">Long Walk</option>
                        <option value="Boxing">Boxing</option>
                        <option value="Strength">Strength</option>
                      </Field>
                      <ErrorMessage
                        name="workout"
                        component="div"
                        className="text-danger"
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label
                        className={` ' form-label mt-3  theme-switcher ${
                          theme === "dark"
                            ? "dark-theme-only-white"
                            : "light-theme"
                        }`}
                      >
                        Select Bodytype
                      </Form.Label>
                      <Field
                        as="select"
                        name="bodytype"
                        className={` ' form-control form-input custom-select  theme-switcher ${
                          theme === "dark"
                            ? "dark-theme-only-color"
                            : "light-theme"
                        }`}
                      >
                        <option value="">Select Bodytype</option>
                        <option value="Slim">Slim</option>
                        <option value="Fit">Fit</option>
                        <option value="Muscular">Muscular</option>
                        <option value="Average">Average</option>
                        <option value="Curvy">Curvy</option>
                      </Field>
                      <ErrorMessage
                        name="bodytype"
                        component="div"
                        className="text-danger"
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label
                        className={` ' form-label mt-3  theme-switcher ${
                          theme === "dark"
                            ? "dark-theme-only-white"
                            : "light-theme"
                        }`}
                      >
                        Hourly Price ($)
                      </Form.Label>
                      <Field
                        type="number"
                        name="hoursPrice"
                        placeholder="Enter Hourly Price"
                        className={` ' form-control form-input  theme-switcher ${
                          theme === "dark"
                            ? "dark-theme-only-color"
                            : "light-theme"
                        }`}
                        onWheel={(e) => e.target.blur()}
                      />
                      <ErrorMessage
                        name="hoursPrice"
                        component="div"
                        className="text-danger"
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label
                        className={`form-label mt-3  theme-switcher ${
                          theme === "dark"
                            ? "dark-theme-only-white"
                            : "light-theme"
                        }`}
                      >
                        Add Hashtags
                      </Form.Label>
                      <App handleSubmitHashtags={handleSubmitHashtags} />
                    </Form.Group>

                    {/* <div className="d-grid gap-2 mt-4">
                      <button className="sign-in-btn mt-4 mb-5" onClick={}>
                        Create profile
                      </button>
                    </div> */}
                    {isSubmitting ? (
                      <button
                        type="submit"
                        className="sign-in-btn my-5"
                        disabled={true}
                      >
                        Creating profile...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="sign-in-btn my-5"
                        disabled={isSubmitting}
                        // onClick={() => handleErrorCheck()}
                      >
                        Create profile
                      </button>
                    )}
                  </FormikForm>
                )}
              </Formik>
              {/* 
              <div className="login-extra-options d-flex justify-content-center mt-4">
                <img src={google} alt="Google Login" />
                <img src={apple} alt="Apple Login" />
              </div>
              <p className="text-center mt-4">
                Already have an account? <Link to="/login">Login here</Link>
              </p> */}
            </div>
          </div>
        </div>
      </div>
      <div>
        <Modal
          open={modelOpenStatus}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={style}
            className={` ' inner-shadow p-0 border-0 border-rduis p-4 theme-switcher ${
              theme === "dark" ? "black-color" : "light-theme"
            }`}
          >
            <img
              src={xmark}
              alt="Close"
              className="x-mark"
              onClick={handleClose}
            />
            <center>
              <img src={LightBulb} alt="Light Bulb" />
            </center>
            <h5 className={`text-center theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}>Welcome to Ask Human</h5>
            <p className={`text-center pt-2 theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}>
              Congratulations on your first step towards getting your answers using chat.
            </p>
            <center className="mt-4">
              {/* <Link to="/home"> */}
              <button
                className="logout-btn mx-2 w-100"
                onClick={handleButtonClick}
              >
                Go to Home
              </button>
              {/* </Link> */}
            </center>
          </Box>
        </Modal>
      </div>
    </div>
  );
}

export default Index;
